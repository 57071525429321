import React, { useEffect, useState } from "react";
import Routes from "./routes/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Box, CircularProgress } from "@mui/material";
import { setProfile } from "./store/actions";
import { makeStyles } from "@mui/styles";
import AOS from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  screenContainer: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#fafafa",
  },
}));

const App = (props) => {
  const classes = useStyles();
  const { isAuthenticated, setProfile, liveTest, verified } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) authenticateUser(token);
    else setLoading(false);
  }, []);

  useEffect(() => {
    const doc = window.document;
    if (!liveTest && doc.fullscreenElement) {
      if (doc?.exitFullscreen) {
        doc?.exitFullscreen();
      } else if (doc?.cancelFullscreen) {
        doc?.cancelFullscreen();
      }
    }
  }, [liveTest]);

  const authenticateUser = async (token) => {
    await setProfile(token);
    setLoading(false);
  };

  if (loading) {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        style={{ height: "90vh" }}
      >
        <CircularProgress size={30} />
      </Box>
    );
  }

  if (isAuthenticated === null)
    return (
      <div className={classes.screenContainer}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={32} />
        </div>
      </div>
    );

  return <Routes verified={verified} />;
};

const mapStateToProps = (state) => {
  const { auth, liveTest } = state;

  return {
    profile: auth.profile,
    isAuthenticated: auth.authenticated,
    liveTest: liveTest.live,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setProfile }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
