import React, { useEffect, useState, Fragment } from "react";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Avatar,
  Tooltip,
  TextField,
  Button,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { Edit, Save } from "react-feather";
import moment from "moment";
import { withFormik } from "formik";
import * as Yup from "yup";

import HeaderIn from "../components/headerIn";
import { updateProfile } from "../store/actions";
import { useTheme } from "@emotion/react";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#fcfcfc",
    paddingTop: "1.5rem",
    height: "100vh",
    overflow: "hidden",
  },
  subContainer: {
    padding: "1rem 10%",
    [theme.breakpoints.down("md")]: {
      padding: ".3rem 2%",
    },
  },
  menu: {
    fontWeight: 600,
    color: "#444",
  },
  cover: {
    background: "#eee",
    borderTopLeftRadius: "2rem",
    height: "30%",
    width: "100%",
    minHeight: "8rem",
    [theme.breakpoints.down("md")]: {
      minHeight: "6rem",
      maxHeight: "6rem",
    },
  },
  profile: {
    marginTop: "-2rem",
    marginLeft: "2rem",
    border: "4px solid #fcfcfc",
    [theme.breakpoints.down("md")]: {
      marginTop: "-1rem",
      marginLeft: "1rem",
    },
  },
}));

const EditableRow = (props) => {
  const {
    property,
    value,
    touched,
    errors,
    values,
    editing,
    handleChange,
    handleBlur,
    focus,
    editable,
    smScreen,
  } = props;
  return (
    <div className="flex w-full my-2">
      <h4
        className="text-slate-500 font-semibold capitalize"
        style={{
          fontSize: "1rem",
          minWidth: "8rem",
        }}
      >
        {property}
      </h4>
      <span
        className="text-slate-600 font-semibold"
        style={{
          fontSize: "1rem",
        }}
      >
        {editing && editable ? (
          <TextField
            id={property}
            error={touched[property] && !!errors[property]}
            disabled={values.submitting}
            fullWidth
            helperText={touched[property] && errors[property]}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values[property]}
            name={property}
            size={"small"}
            autoFocus={focus}
            style={{
              minWidth: smScreen ? "10rem" : "18rem",
            }}
          />
        ) : (
          value
        )}
      </span>
    </div>
  );
};

const Profile = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    profile,
    touched,
    errors,
    values,
    setValues,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
  } = props;

  useEffect(() => {}, []);

  const toggleEditing = () => {
    if (values.editing) {
      resetForm();
      setFieldValue("editing", false);
    } else {
      setValues({
        ...values,
        name: profile?.name,
        email: profile?.email,
        editing: true,
      });
    }
  };

  const updateClick = async () => {
    await handleSubmit();
  };

  const discardChangesClick = () => {
    resetForm();
    setFieldValue("editing", false);
  };

  return (
    <div className={`flex w-full ${classes.container}`}>
      <HeaderIn />
      <div className={`flex w-full h-full ${classes.subContainer}`}>
        <div className="flex w-full h-full flex-col">
          <div className={classes.cover} data-aos="fade-in"></div>
          <div className="flex">
            <Avatar
              src={profile?.profile_picture}
              variant="circular"
              className={classes.profile}
              style={{
                width: smScreen ? "4rem" : "6rem",
                height: smScreen ? "4rem" : "6rem",
                backgroundColor: "orange",
                fontSize: "1.5rem",
                zIndex: 2,
                textTransform: "uppercase",
              }}
              data-aos="zoom-in"
            >
              {profile?.name?.charAt(0)}
            </Avatar>
            <div className="flex w-full justify-between py-1 md:py-3 pr-1 md:pr-2 overflow-hidden">
              <div className="flex flex-col flex-1 w-full">
                <div
                  className="text-18 font-semibold pl-1 md:pl-4 capitalize text-gray-600 truncate"
                  data-aos="slide-right"
                  style={{
                    zIndex: 1,
                  }}
                >
                  {profile?.name}
                </div>
                <div
                  className="text-12 font-semibold pl-1 md:pl-4 text-gray-400"
                  data-aos="slide-right"
                  style={{
                    zIndex: 1,
                  }}
                >
                  Joined Delvex in{" "}
                  {moment(profile?.createdAt).format("MMM YYYY")}
                </div>
              </div>
              <div>
                {values.editing ? (
                  <div className="w-full justify-end md:flex hidden">
                    <Button
                      style={{
                        backgroundColor: "#14C38E",
                        color: "white",
                        boxShadow: "none",
                        padding: "6px 18px",
                        fontSize: ".8rem",
                        borderRadius: "8px",
                        textTransform: "capitalize",
                        fontWeight: 700,
                      }}
                      onClick={() => updateClick()}
                      variant="contained"
                    >
                      {values?.submitting ? (
                        <Fragment>
                          <CircularProgress
                            size={12}
                            style={{
                              color: "white",
                              marginRight: ".8rem",
                            }}
                          />
                          <span className="hidden md:block">updating ...</span>
                        </Fragment>
                      ) : (
                        "save changes"
                      )}
                    </Button>
                    <Button
                      style={{
                        background: "#F1F1F1",
                        color: "#666",
                        marginLeft: ".7rem",
                        boxShadow: "none",
                        padding: "6px 18px",
                        fontSize: ".8rem",
                        borderRadius: "8px",
                        textTransform: "capitalize",
                        fontWeight: 700,
                      }}
                      onClick={() => discardChangesClick()}
                      variant="contained"
                    >
                      discard changes
                    </Button>
                  </div>
                ) : (
                  <Tooltip title="Edit profile">
                    <Edit
                      size={22}
                      className="cursor-pointer"
                      color="#444"
                      onClick={() => toggleEditing()}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          <div
            className="flex w-full md:h-full flex-col"
            style={{
              padding: "0 2.4rem",
              paddingTop: "3.6rem",
            }}
          >
            <EditableRow
              property={"name"}
              value={profile?.name}
              touched={touched}
              errors={errors}
              values={values}
              editing={values.editing}
              handleChange={handleChange}
              handleBlur={handleBlur}
              focus={true}
              smScreen={smScreen}
              editable
            />
            <EditableRow
              property={"email"}
              value={profile?.email}
              touched={touched}
              errors={errors}
              values={values}
              editing={false}
              handleChange={handleChange}
              handleBlur={handleBlur}
              smScreen={smScreen}
              editable
            />
            <EditableRow
              property={"experience"}
              value={!profile?.score ? "N/A" : profile?.score}
              touched={touched}
              errors={errors}
              values={values}
              editing={false}
              handleChange={handleChange}
              handleBlur={handleBlur}
              smScreen={smScreen}
              editable={false}
            />
          </div>
          <div className="flex w-full justify-end items-center md:hidden px-8 py-6">
            {values.editing && (
              <div className="flex w-full justify-end">
                <Button
                  style={{
                    backgroundColor: "#14C38E",
                    color: "white",
                    boxShadow: "none",
                    padding: "6px 18px",
                    fontSize: ".8rem",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    fontWeight: 700,
                  }}
                  onClick={() => updateClick()}
                  variant="contained"
                >
                  {values?.submitting ? (
                    <Fragment>
                      <CircularProgress
                        size={18}
                        style={{
                          color: "white",
                        }}
                      />
                    </Fragment>
                  ) : (
                    "save"
                  )}
                </Button>
                <Button
                  style={{
                    background: "#F1F1F1",
                    color: "#666",
                    marginLeft: ".7rem",
                    boxShadow: "none",
                    padding: "6px 18px",
                    fontSize: ".8rem",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    fontWeight: 700,
                  }}
                  onClick={() => discardChangesClick()}
                  variant="contained"
                >
                  discard
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProfileForm = withFormik({
  mapPropsToValues: () => ({
    name: "",
    submitting: false,
    editing: false,
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required("Name is required"),
  }),

  handleSubmit: async (
    values,
    { resetForm, setSubmitting, setValues, props, ...formikProps }
  ) => {
    try {
      setValues({ ...values, submitting: true });
      const updated = await props.updateProfile(values);
      resetForm();
      setValues({ ...values, submitting: false, editing: false });
    } catch (error) {
      setValues({ ...values, submitting: false });
      // setSubmitting(false);
      // props.toggle();
      return false;
    }
  },

  displayName: "profileForm",
})(Profile);

const mapStateToProps = (state) => {
  return {
    profile: state.auth?.profile,
    isAuthenticated: state.auth?.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (values) => dispatch(updateProfile(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
