import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { ChevronLeft, Eye, EyeOff, GitHub } from "react-feather";
import { useHistory } from "react-router-dom";
import { withFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { register, toggleSnakebar } from "../store/actions";

const useStyles = makeStyles((theme) => ({
  container_cr: {
    width: "50vw",
    overflow: "hidden",
  },
  box1: {
    paddingBottom: "8rem",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  box2: {},
  move: {},
  header: {
    fontSize: "1rem",
    fontWeight: "500",
    fontFamily: "Raleway",
    margin: "0 .5rem",
    color: "#fffff",
  },
}));

const infographics = [
  {
    img: "cloud_hosting",
    text: "Practice your skills to get ready for your next exam",
  },
  { img: "blooming", text: "Move your tech journey forward" },
  {
    img: "check",
    text: "Master your domain, ace the certification exams",
  },
  {
    img: "track_progress",
    text: "Get the experience of actual test environment",
  },
];

const RegisterPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [selected, setSelected] = useState(0);
  const [int, setInt] = useState(null);
  const [showPass, setShowPass] = useState({
    pass: false,
    confirm: false,
  });

  const {
    values,
    errors,
    touched,
    setErrors,
    setTouched,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
    toggleSnakebar,
    isAuthenticated,
  } = props;

  const startCorousel = () => {
    const inte = setInterval(() => {
      setSelected((selected) => (selected + 1) % infographics.length);
    }, 4000);
    setInt(inte);
  };

  useEffect(() => {
    setSelected(0);
    if (!int) {
      startCorousel();
    }
    return () => {
      if (int) clearInterval(int);
    };
  }, []);

  if (isAuthenticated) {
    history.replace("/app/profile");
    return null;
  }

  const toggleShowPass = (field) => {
    setShowPass((state) => ({
      ...state,
      [field]: !state[field],
    }));
  };

  return (
    <div
      className={`flex w-screen h-screen overflow-hidden transition-all ease-in-out bg-blue-500
       ${classes.container}`}
    >
      <div
        className={`flex flex-col w-full md:w-1/2 bg-white ${classes.box1} overflow-scroll`}
        style={{
          paddingBottom: "3rem",
        }}
      >
        <div className="w-full flex h-14 justify-start items-center p-2 md:p-8">
          <ChevronLeft
            size={22}
            color="#888"
            className="mr-2 cursor-pointer"
            onClick={() => history.push("/")}
          />
          <img
            src="/images/logo.png"
            width="30"
            height="30"
            style={{
              zIndex: 1400,
            }}
          />
          <span className={classes.header} data-aos="slide-right">
            Welcome to DelveX
          </span>
        </div>
        <div
          className="h-full flex flex-col w-4/5 self-center md:items-start items-center py-14 md:pt-14 pt-32"
          data-aos="fade-right"
        >
          <h4 className="text-2xl font-semibold text-slate-700 mb-2">
            Sign Up
          </h4>
          <p className="text-14 text-gray-500 mb-10">
            Create Account to start using delvex
          </p>
          <div className="w-full md:w-4/6 mb-6">
            <TextField
              placeholder="john@gmail.com"
              type="email"
              label="Email"
              fullWidth
              size="small"
              name="email"
              variant="outlined"
              disabled={values.submitting}
              required={true}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched?.email && errors?.email}
              helperText={touched?.email && errors?.email}
              value={values.email}
            />
          </div>
          <div className="w-full md:w-4/6 mb-6">
            <TextField
              placeholder="John"
              label="Name"
              size="small"
              variant="outlined"
              required={true}
              name="name"
              fullWidth
              onChange={handleChange}
              disabled={values.submitting}
              onBlur={handleBlur}
              error={touched?.name && errors?.name}
              helperText={touched?.name && errors?.name}
              value={values.name}
            />
          </div>
          <div className="w-full md:w-4/6 mb-6">
            <TextField
              name="password"
              type={showPass.pass ? "text" : "password"}
              label="Password"
              size="small"
              required={true}
              variant="outlined"
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched?.password && errors?.password}
              disabled={values.submitting}
              helperText={touched?.password && errors?.password}
              value={values.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="cursor-pointer">
                    {showPass.pass ? (
                      <Eye size={20} onClick={() => toggleShowPass("pass")} />
                    ) : (
                      <EyeOff
                        size={20}
                        onClick={() => toggleShowPass("pass")}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="w-full md:w-4/6 mb-6">
            <TextField
              name="confirm_password"
              type={showPass.confirm ? "text" : "password"}
              label="Confirm Password"
              size="small"
              required={true}
              variant="outlined"
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={values.submitting}
              error={touched?.confirm_password && errors?.confirm_password}
              helperText={touched?.confirm_password && errors?.confirm_password}
              value={values.confirm_password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="cursor-pointer">
                    {showPass.confirm ? (
                      <Eye
                        size={20}
                        onClick={() => toggleShowPass("confirm")}
                      />
                    ) : (
                      <EyeOff
                        size={20}
                        onClick={() => toggleShowPass("confirm")}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="w-full md:w-4/6 mt-4">
            <Button
              variant="contained"
              fullWidth
              size="small"
              style={{
                background: "dodgerblue",
                padding: "8px 0",
                fontWeight: "600",
                borderRadius: ".9rem",
              }}
              data-aos="zoom-in"
              data-aos-offset={40}
              data-aos-once={true}
              onClick={(e) => !values.submitting && handleSubmit(e)}
            >
              {values.submitting ? (
                <CircularProgress
                  size={20}
                  style={{
                    color: "white",
                  }}
                />
              ) : (
                "SIGN UP"
              )}
            </Button>
          </div>
          <div className="flex items-center justify-center w-full md:w-4/6 py-6">
            <hr
              className="w-2/5"
              data-aos="slide-left"
              data-aos-delay={500}
              data-aos-offset={40}
              data-aos-once={true}
            />
            <span
              className="px-4 bg-white"
              style={{
                zIndex: 1500,
              }}
            >
              or
            </span>
            <hr
              className="w-2/5"
              data-aos="slide-right"
              data-aos-delay={500}
              data-aos-offset={40}
              data-aos-once={true}
            />
          </div>
          <div className="flex md:flex-row flex-col items-center w-full md:w-4/6">
            <div
              className="flex rounded-md bg-slate-100 p-2 items-center justify-center md:mr-4 md:mb-0 mb-4 cursor-pointer hover:bg-slate-200 transition-all ease-in-out flex-1 w-full"
              onClick={() =>
                window.open(
                  process.env.REACT_APP_BACKEND_URL + "/auth/github",
                  "_self"
                )
              }
            >
              <GitHub size={20} color="#444" strokeWidth={2.5} />
              <span className="pl-2 items-center flex text-slate-600 font-semibold text-14">
                Login with Github
              </span>
            </div>
            <div
              className="flex w-full rounded-md bg-slate-100 p-2 items-center justify-center cursor-pointer  hover:bg-slate-200 transition-all ease-in-out flex-1"
              onClick={() =>
                window.open(
                  process.env.REACT_APP_BACKEND_URL + "/auth/google",
                  "_self"
                )
              }
            >
              <GoogleIcon
                style={{
                  fontSize: "1.2rem",
                  color: "#444",
                }}
              />
              <span className="pl-2 items-center flex text-slate-600 font-semibold text-14">
                Login with Google
              </span>
            </div>
          </div>
          <div className="flex w-full md:justify-start justify-center py-8 text-gray-600">
            Already an user?
            <span
              className="text-blue-600 font-bold px-2 pl-4 hover:text-blue-500 cursor-pointer"
              onClick={() => history.push("/login")}
            >
              Login
            </span>{" "}
            instead
          </div>
        </div>
      </div>
      <div
        className={`flex justify-center items-center flex-col w-1/2 bg-blue-500 md:flex h-full ${classes.box2} md:block hidden`}
        data-aos="slide-right"
      >
        <img
          src={`/images/${infographics[selected].img}.svg`}
          data-aos="zoom-in"
          style={{
            width: "40%",
            height: "40%",
            transition: "all ease-in-out 1s",
          }}
          data-aos="slide-left"
          data-aos-delay={1000}
        />
        <div
          className="flex justify-center items-center w-full text-white font-semibold my-4"
          style={{
            fontFamily: "Raleway",
          }}
          data-aos="fade-in"
        >
          {infographics[selected].text}
        </div>
      </div>
    </div>
  );
};

const RegisterPageEnhanced = withFormik({
  mapPropsToValues: () => ({
    email: "",
    name: "",
    password: "",
    confirm_pass: "",
    submitting: false,
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Invalid email")
      .required("Email is required"),
    name: Yup.string().required("Name is required"),
    password: Yup.string()
      .required("Enter your password")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(
        /[a-zA-Z0-9!@#$&*]/,
        "Password can only contain Latin letters, digits. special symbols"
      ),
    confirm_password: Yup.string()
      .min(8)
      .required("Required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  }),

  handleSubmit: async (values, { props, setFieldError, setFieldValue }) => {
    try {
      let { email, password, name } = values;
      const { register, toggleSnakebar, history } = props;
      name = name.trim();
      if (!name) {
        setFieldValue("name", "");
        return setFieldError("name", "Name is required");
      }
      setFieldValue("submitting", true);
      const resp = await register({
        name,
        email,
        password,
      });
      if (resp?.success) {
        setFieldValue("submitting", false);
        toggleSnakebar(true, "Registered successfully", "success");
        return history.replace(`/verify?email=${email}`, {
          registered: true,
          email,
          sent: true,
        });
      }
      toggleSnakebar(true, resp?.error, "error");
      setFieldValue("submitting", false);
    } catch (error) {
      setFieldValue("submitting", false);
    }
  },

  displayName: "RegisterPageForm",
})(RegisterPage);

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth?.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSnakebar: (show, msg, severity) =>
      dispatch(toggleSnakebar(show, msg, severity)),
    register: (payload) => dispatch(register(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPageEnhanced);
