import React, { useState, useEffect, Fragment } from "react";
import {
  CircularProgress,
  Button,
  Tooltip,
  SwipeableDrawer,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useTheme } from "@emotion/react";
import TripOriginIcon from "@mui/icons-material/TripOrigin";

import { ChevronLeft, ChevronRight, Clock, Lock, X } from "react-feather";
import { loadQuestion, submitAnswer, quitTest } from "../store/actions";
import * as moment from "moment";
import useInterval from "../utilities/useInterval";
import "./codeBlock.css";

const SummaryLine = (props) => {
  const { text, icon, number } = props;
  return (
    <div className="flex w-full justify-between items-center py-1">
      <div className="flex justify-start items-center">
        {icon}
        <span
          style={{
            paddingLeft: ".6rem",
            fontWeight: 600,
            color: "#444",
            fontSize: ".9rem",
          }}
        >
          {text}
        </span>
      </div>
      <div
        style={{
          fontWeight: 600,
          color: "#333",
          fontSize: ".9rem",
        }}
      >
        {number}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    transition: "all ease-in-out .2s",
    background: "#fff",
    boxShadow: "2px 4px 8px 1px #dadada",
    height: "20rem",
    width: "18rem",
    borderRadius: "1.4rem",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "8px 4px 8px 1px #dadada",
    },
  },
  title: {
    fontSize: "1.4rem",
    paddingLeft: ".8rem",
  },
  upFirst: {
    "&:first-child": {
      textTransform: "uppercase",
    },
  },
}));

const QuestionPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { liveTest, submitAnswer, loadQuestion, quitTest } = props;

  const {
    live,
    prevQuestionId,
    currentQuestionId,
    question,
    options,
    loading,
    totalQuestions,
    currentStatus,
    qNumber,
    snapshotId,
    isLast,
    currentQuestionNumber,
    displayingQuestion,
    testName,
    levelName,
    startedAt,
    levelId,
    msq,
  } = liveTest;

  const [loader, setLoader] = useState(true);
  const [examId, setExamId] = useState(null);
  const [selected, setSelected] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [skipping, setSkipping] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const callApi = async () => {
    const { exam_id } = props.match.params;
    if (!live && !snapshotId) return history.push(`/app/exams/${exam_id}`);
    setLoader(true);
    setExamId(exam_id);
    setLoader(false);
  };

  const duration = moment.duration(
    moment(moment(startedAt).add(liveTest.duration || 30, "minutes")).diff()
  );

  let intervalCleared = false;

  useInterval(() => {
    if (intervalCleared) return;
    const secondsLeft = duration.asSeconds();
    const timeLeftString = moment.utc(secondsLeft * 1000).format("mm:ss");
    if (secondsLeft <= 1) {
      intervalCleared = true;
      setLoader(true);
      return quitTest(levelId);
    }
    setTimeLeft(timeLeftString);
  }, 1000);

  useEffect(() => {
    const doc = window.document;
    // if (!doc.fullscreenElement) doc.body.requestFullscreen();
    return () => {
      // if (!liveTest && doc.fullscreenElement) {
      //   if (doc?.exitFullscreen) {
      //     doc?.exitFullscreen();
      //   } else if (doc?.cancelFullscreen) {
      //     doc?.cancelFullscreen();
      //   }
      // }
    };
  }, []);

  useEffect(() => {
    callApi();
  }, []);

  if (snapshotId) {
    history.push(`/app/report/${snapshotId}`);
    return <div />;
  }

  const select = (selectedIndex) => {
    if (!msq) return setSelected([selectedIndex]);
    if (selected.includes(selectedIndex)) {
      setSelected(selected.filter((index) => index !== selectedIndex));
    } else {
      setSelected([...selected, selectedIndex]);
    }
  };

  const checkSubmission = async (skipped) => {
    skipped ? setSkipping(true) : setSubmitting(true);
    await submitAnswer(skipped ? null : selected, skipped);
    setSelected([]);
    skipped ? setSkipping(false) : setSubmitting(false);
  };

  const loadQuestionRequest = async (questionNumber) => {
    const selectedAnswer = await loadQuestion(questionNumber);
    setSelected([selectedAnswer]);
  };

  const toggleDrawer = () => {
    setDrawer(!drawer);
  };

  return (
    <div className="flex flex-col w-full h-full overflow-auto">
      {loader ? (
        <div
          className="flex min-w-full justify-center items-center"
          style={{
            height: "70vh",
          }}
        >
          <CircularProgress size={32} />
        </div>
      ) : (
        <div className="flex w-full h-full">
          <ChevronRight
            size={32}
            className="flex justify-center items-center md:hidden"
            style={{
              background: "#dadada",
              color: "#333",
              borderRadius: "50%",
              padding: ".5rem",
              cursor: "pointer",
              transition: "all ease-in-out .2s",
              position: "absolute",
              top: "2rem",
              left: "-8px",
            }}
          />
          <SwipeableDrawer
            anchor={"left"}
            open={drawer}
            onClose={() => toggleDrawer()}
            onOpen={() => toggleDrawer()}
            style={{
              width: "90vw",
              maxWidth: "90vw",
            }}
          >
            <div
              className="flex flex-col h-full bg-gray-50 w-full"
              style={{
                overflow: "hidden",
                boxShadow: "0px 4px 8px 4px #dfdfdf",
                padding: ".7rem",
                width: "80vw",
              }}
            >
              <div className="flex w-full truncate items-center">
                <Tooltip title="Quit & go back">
                  <ChevronLeft
                    size={28}
                    color="#666"
                    onClick={() => {
                      setLoader(true);
                      quitTest(levelId);
                    }}
                    style={{
                      cursor: "pointer",
                      fontWeight: 900,
                      marginRight: ".7rem",
                      fontSize: "3.5rem",
                    }}
                  />
                </Tooltip>
                <div className="flex flex-col py-2 flex-1 overflow-hidden">
                  <span
                    className="truncate text-slate-600"
                    style={{
                      fontSize: "1rem",
                      fontWeight: 700,
                    }}
                  >
                    {levelName}
                  </span>
                  <span
                    className="truncate text-slate-500"
                    style={{
                      fontSize: ".9rem",
                    }}
                  >
                    {testName}
                  </span>
                </div>
                <div
                  className="flex items-center justify-center"
                  style={{
                    width: "1.5rem",
                  }}
                >
                  <X
                    size={28}
                    className="cursor-pointer text-slate-500 font-semibold"
                    onClick={() => toggleDrawer()}
                  />
                </div>
              </div>
              <hr
                className="flex w-full my-4"
                style={{
                  color: "#444",
                }}
              />
              <div className="flex flex-col">
                <div
                  style={{
                    fontSize: ".9rem",
                    color: "#444",
                    margin: ".6rem 0 .6rem 0",
                  }}
                >
                  Test Summary
                </div>
                <SummaryLine
                  text="Total Questions"
                  number={totalQuestions}
                  icon={
                    <TripOriginIcon
                      style={{
                        color: "#7fb77e",
                        background: "transparent",
                      }}
                    />
                  }
                />
                <SummaryLine
                  text="Attempted"
                  number={currentStatus?.reduce(
                    (a, i) => (i !== 0 ? a + 1 : a),
                    0
                  )}
                  icon={
                    <TripOriginIcon
                      style={{
                        color: "#A6D1E6",
                        background: "transparent",
                      }}
                    />
                  }
                />
              </div>
              <hr
                className="flex w-full my-4"
                style={{
                  color: "#444",
                }}
              />
              <div
                className="flex capitalize w-full font-semibold text-slate-500 p-2"
                style={{
                  fontSize: ".9rem",
                }}
              >
                Question List
              </div>
              <div className="flex w-full flex-wrap overflow-y-auto">
                {Array.from(Array(totalQuestions).keys()).map((index) => (
                  <div className="relative" key={index}>
                    {index >= qNumber && (
                      <Lock
                        size={22}
                        style={{
                          position: "absolute",
                          left: "35%",
                          top: "30%",
                          zIndex: 10,
                          color: "#444",
                        }}
                      />
                    )}
                    <div
                      className={`bg-slate-100 rounded-xl text-slate-700 p-3 flex items-center justify-center w-12 font-semibold m-2 shadow-md shadow-slate-200 ${
                        index >= qNumber ? "cursor-default" : "cursor-pointer"
                      }`}
                      style={{
                        filter: index >= qNumber ? "blur(2px)" : "",
                        transform:
                          displayingQuestion === index + 1 ? "scale(1.05)" : "",
                        transition: "all ease-in-out .2s",
                        color:
                          index >= qNumber
                            ? "transparent"
                            : displayingQuestion === index + 1
                            ? "#fff"
                            : "",
                        backgroundColor:
                          displayingQuestion === index + 1
                            ? "#92B4EC"
                            : "rgb(224, 242, 254)",
                        zIndex: 9,
                      }}
                      onClick={() =>
                        index < qNumber && loadQuestionRequest(index + 1)
                      }
                    >
                      {index + 1}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </SwipeableDrawer>

          <div
            className="md:flex flex-col h-full bg-gray-50 hidden"
            style={{
              width: "24rem",
              maxWidth: "24rem",
              overflow: "hidden",
              boxShadow: "0px 4px 8px 4px #dfdfdf",
              padding: "1rem",
            }}
            data-aos="slide-right"
            data-aos-once={true}
          >
            <div className="flex w-full truncate items-center">
              <Tooltip title="Quit & go back">
                <ChevronLeft
                  size={28}
                  color="#666"
                  onClick={() => {
                    setLoader(true);
                    quitTest(levelId);
                  }}
                  style={{
                    cursor: "pointer",
                    fontWeight: 900,
                    marginRight: ".7rem",
                    fontSize: "3.5rem",
                  }}
                />
              </Tooltip>
              <div className="flex flex-col py-2">
                <span
                  className="truncate text-slate-600"
                  style={{
                    fontSize: "1rem",
                    fontWeight: 700,
                  }}
                >
                  {levelName}
                </span>
                <span
                  className="truncate text-slate-500"
                  style={{
                    fontSize: ".9rem",
                  }}
                >
                  {testName}
                </span>
              </div>
            </div>
            <hr
              className="flex w-full my-4"
              style={{
                color: "#444",
              }}
            />
            <div className="flex flex-col">
              <div
                style={{
                  fontSize: ".9rem",
                  color: "#444",
                  margin: ".6rem 0 .6rem 0",
                }}
              >
                Test Summary
              </div>
              <SummaryLine
                text="Total Questions"
                number={totalQuestions}
                icon={
                  <TripOriginIcon
                    style={{
                      color: "#7fb77e",
                      background: "transparent",
                    }}
                  />
                }
              />
              <SummaryLine
                text="Attempted"
                number={currentStatus?.reduce(
                  (a, i) => (i !== 0 ? a + 1 : a),
                  0
                )}
                icon={
                  <TripOriginIcon
                    style={{
                      color: "#A6D1E6",
                      background: "transparent",
                    }}
                  />
                }
              />
            </div>
            <hr
              className="flex w-full my-4"
              style={{
                color: "#444",
              }}
            />
            <div
              className="flex capitalize w-full font-semibold text-slate-500 p-2"
              style={{
                fontSize: ".9rem",
              }}
            >
              Question List
            </div>
            <div className="flex w-full flex-wrap overflow-y-auto">
              {Array.from(Array(totalQuestions).keys()).map((index) => (
                <div className="relative" key={index}>
                  {index >= qNumber && (
                    <Lock
                      size={22}
                      style={{
                        position: "absolute",
                        left: "35%",
                        top: "30%",
                        zIndex: 10,
                        color: "#444",
                      }}
                    />
                  )}
                  <div
                    className={`bg-slate-100 rounded-xl text-slate-700 p-3 flex items-center justify-center w-12 font-semibold m-2 shadow-md shadow-slate-200 ${
                      index >= qNumber ? "cursor-default" : "cursor-pointer"
                    }`}
                    style={{
                      filter: index >= qNumber ? "blur(2px)" : "",
                      transform:
                        displayingQuestion === index + 1 ? "scale(1.05)" : "",
                      transition: "all ease-in-out .2s",
                      color:
                        index >= qNumber
                          ? "transparent"
                          : displayingQuestion === index + 1
                          ? "#fff"
                          : "",
                      backgroundColor:
                        displayingQuestion === index + 1
                          ? "#92B4EC"
                          : "rgb(224, 242, 254)",
                      zIndex: 9,
                    }}
                    onClick={() =>
                      index < qNumber && loadQuestionRequest(index + 1)
                    }
                  >
                    {index + 1}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col w-full h-full justify-between relative">
            <div
              className={`absolute right-12 top-8 flex justify-center items-center bg-slate-100 rounded-lg px-3 py-2 text-slate-600 ${
                !timeLeft && "hidden"
              }`}
              style={{
                fontSize: ".8rem",
                width: "6rem",
              }}
            >
              <Clock
                size={16}
                style={{
                  color: "#666",
                  marginRight: ".4rem",
                }}
              />
              {timeLeft}
            </div>
            <div
              className="flex flex-col w-full h-full"
              style={
                !smScreen
                  ? { paddingLeft: "10%", paddingTop: "10%" }
                  : { padding: "14px", paddingTop: "4rem" }
              }
            >
              <h4 className="bg-slate-100 rounded-xl text-slate-700 p-3 flex items-center w-12 font-semibold content-center justify-center">
                {displayingQuestion}
              </h4>
              <hr
                style={{
                  width: "82%",
                  color: "#444",
                  margin: "1rem 0",
                }}
              />
              <div
                style={{ padding: ".7rem 0 1.4rem 0" }}
                className={`lowercase ${classes.upFirst}`}
              >
                <div dangerouslySetInnerHTML={{ __html: question }} />
              </div>
              <div
                style={{ padding: ".7rem 0 1.4rem 0" }}
                className={`lowercase ${classes.upFirst}`}
              >
                {msq ? (
                  <div style={{ color: "rgb(71 85 105)", fontSize: ".8rem" }}>
                    Select one or more options.
                  </div>
                ) : (
                  <div style={{ color: "rgb(71 85 105)", fontSize: ".8rem" }}>
                    Pick one answer
                  </div>
                )}
              </div>
              <div className={`flex w-full flex-wrap relative`}>
                {options?.map((option, index) => {
                  const answer = selected?.includes(index + 1);

                  return (
                    <div
                      className={`flex items-center w-full md:w-1/2 mb-2 md:mb-4 ${
                        index % 2 == 0 ? "justify-start" : "justify-start"
                      }`}
                      data-aos="zoom-in"
                      data-aos-once={true}
                    >
                      <div
                        className={`flex w-3/4 justify-start items-center shadow-md shadow-gray-200`}
                        style={{
                          border: `1px solid ${answer ? "#A6D1E6" : "#e1e1e1"}`,
                          background: `${answer ? "#ccdde6" : "#fff"}`,
                          cursor:
                            currentQuestionNumber === displayingQuestion &&
                            !submitting
                              ? "pointer"
                              : "default",
                          borderRadius: "8px",
                          padding: "12px",
                          width: smScreen ? "100%" : "28rem",
                        }}
                        onClick={() =>
                          currentQuestionNumber === displayingQuestion &&
                          !submitting &&
                          select(index + 1)
                        }
                      >
                        <span
                          style={{
                            fontSize: "1rem",
                            fontWeight: 700,
                            padding: "4px 10px",
                            background: answer ? "#A6D1E6" : "#dfdfdf",
                            borderRadius: "50%",
                            marginRight: "8px",
                          }}
                        >
                          {String.fromCharCode(65 + index)}
                        </span>
                        <span>{option?.text}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className={`flex w-full justify-end`}
              style={{
                height: "5rem",
                padding: "0 4rem",
              }}
            >
              <Button
                style={{
                  backgroundColor: "white",
                  color: "#555",
                  justifyContent: "center",
                  boxShadow: "none",
                  padding: skipping ? "8px 28px" : "8px 22px",
                  maxHeight: "2.6rem",
                  borderRadius: "4px",
                  border: "1px solid #aaa",
                  textTransform: "capitalize",
                  marginRight: ".7rem",
                  fontWeight: 700,
                }}
                onClick={() => checkSubmission(true)}
                variant="contained"
                disabled={
                  submitting ||
                  skipping ||
                  currentQuestionNumber !== displayingQuestion
                }
              >
                {skipping ? (
                  <CircularProgress
                    size={18}
                    style={{
                      color: "black",
                    }}
                  />
                ) : (
                  "Skip"
                )}
              </Button>
              <Button
                style={{
                  backgroundColor:
                    currentQuestionNumber !== displayingQuestion ||
                    submitting ||
                    !selected ||
                    !selected?.length ||
                    selected?.length === 0
                      ? "#dfdfdf"
                      : "#14C38E",
                  color:
                    currentQuestionNumber !== displayingQuestion ||
                    submitting ||
                    !selected ||
                    !selected?.length ||
                    selected?.length === 0
                      ? "black"
                      : "white",
                  boxShadow: "none",
                  padding: "8px 18px",
                  marginRight: ".6rem",
                  maxHeight: "2.6rem",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  marginRight: ".7rem",
                  fontWeight: 700,
                }}
                onClick={() => checkSubmission(false)}
                containe
                disabled={
                  currentQuestionNumber !== displayingQuestion ||
                  skipping ||
                  submitting ||
                  !selected?.length
                }
              >
                {submitting ? (
                  <Fragment>
                    <CircularProgress
                      size={18}
                      style={{
                        color: "#444",
                        marginRight: ".8rem",
                      }}
                    />
                    <span>Submitting...</span>
                  </Fragment>
                ) : isLast ? (
                  "Submit Test"
                ) : (
                  "Submit & Next"
                )}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    liveTest: state.liveTest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitAnswer: (answer, skipped) => dispatch(submitAnswer(answer, skipped)),
    loadQuestion: (questionNumber) => dispatch(loadQuestion(questionNumber)),
    quitTest: (levelId) => dispatch(quitTest(levelId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPage);
