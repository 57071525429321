const mock_data = {
  courses: [
    {
      category: "AWS",
      icon: "https://images.techhive.com/images/article/2015/05/aws-logo-100584713-orig.jpg?auto=webp&quality=85,70",
      count: 4,
    },
    {
      icon: "https://www.docker.com/wp-content/uploads/2022/03/vertical-logo-monochromatic.png",
      category: "DOCKER",
      count: 4,
    },
    {
      icon: "https://upload.wikimedia.org/wikipedia/commons/0/00/Kubernetes_%28container_engine%29.png",
      category: "Kubernetes",
      count: 4,
    },
    {
      icon: "https://git-scm.com/images/logos/downloads/Git-Icon-1788C.png",
      category: "GIT",
      count: 2,
    },
    {
      icon: "https://images.techhive.com/images/article/2015/05/aws-logo-100584713-orig.jpg?auto=webp&quality=85,70",
      category: "AWS",
      count: 4,
    },
    {
      icon: "https://cdn-icons-png.flaticon.com/512/873/873117.png",
      category: "GCP",
      count: 3,
    },
    {
      icon: "https://images.techhive.com/images/article/2015/05/aws-logo-100584713-orig.jpg?auto=webp&quality=85,70",
      category: "AWS",
      count: 4,
    },
    {
      icon: "https://swimburger.net/media/ppnn3pcl/azure.png",
      category: "MS Azure",
      count: 3,
    },
    {
      icon: "https://images.techhive.com/images/article/2015/05/aws-logo-100584713-orig.jpg?auto=webp&quality=85,70",
      category: "AWS",
      count: 4,
    },
  ],
  reviews: [
    {
      id: 0,
      company: "Google",
      name: "Cristina",
      review: 5,
      designation: "Devops engineer",
      profile_picture:
        "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1588&q=80",
      text: "Magna minim est excepteur labore do pariatur et non. Excepteur elit laboris commodo esse pariatur. Labore ullamco veniam qui veniam non. Lorem Lorem quis fugiat id minim irure nulla cupidatat do reprehenderit. Ex sunt incididunt deserunt ullamco exercitation fugiat labore eu exercitation labore. Magna nulla ex eiusmod ad nisi amet officia laboris reprehenderit ad consequat. Excepteur eiusmod ut magna officia incididunt id commodo non excepteur officia enim duis eiusmod.  Duis labore esse sunt esse quis minim deserunt fugiat qui ipsum. Dolore reprehenderit mollit ea anim occaecat excepteur enim ex pariatur cupidatat. Aliqua deserunt et amet eu nulla eu culpa eu laboris culpa aliquip occaecat. Ut cillum duis nostrud non et qui duis officia. Sit voluptate elit culpa incididunt elit irure ad labore deserunt veniam ipsum minim voluptate. Veniam laboris aliquip eu exercitation anim aute.",
    },
    {
      id: 1,
      company: "Cisco",
      name: "Albert dera",
      review: 5,
      designation: "Software developer",
      profile_picture:
        "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      text: "Magna minim est excepteur labore do pariatur et non. Excepteur elit laboris commodo esse pariatur. Labore ullamco veniam qui veniam non. Lorem Lorem quis fugiat id minim irure nulla cupidatat do reprehenderit. Ex sunt incididunt deserunt ullamco exercitation fugiat labore eu exercitation labore. Magna nulla ex eiusmod ad nisi amet officia laboris reprehenderit ad consequat. Excepteur eiusmod ut magna officia incididunt id commodo non excepteur officia enim duis eiusmod.  Duis labore esse sunt esse quis minim deserunt fugiat qui ipsum. Dolore reprehenderit mollit ea anim occaecat excepteur enim ex pariatur cupidatat. Aliqua deserunt et amet eu nulla eu culpa eu laboris culpa aliquip occaecat. Ut cillum duis nostrud non et qui duis officia. Sit voluptate elit culpa incididunt elit irure ad labore deserunt veniam ipsum minim voluptate. Veniam laboris aliquip eu exercitation anim aute.",
    },
    {
      id: 2,
      company: "T mobile",
      name: "ellie hoast",
      review: 5,
      designation: "Software developer intern",
      profile_picture:
        "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1528&q=80",
      text: "Magna minim est excepteur labore do pariatur et non. Excepteur elit laboris commodo esse pariatur. Labore ullamco veniam qui veniam non. Lorem Lorem quis fugiat id minim irure nulla cupidatat do reprehenderit. Ex sunt incididunt deserunt ullamco exercitation fugiat labore eu exercitation labore. Magna nulla ex eiusmod ad nisi amet officia laboris reprehenderit ad consequat. Excepteur eiusmod ut magna officia incididunt id commodo non excepteur officia enim duis eiusmod.  Duis labore esse sunt esse quis minim deserunt fugiat qui ipsum. Dolore reprehenderit mollit ea anim occaecat excepteur enim ex pariatur cupidatat. Aliqua deserunt et amet eu nulla eu culpa eu laboris culpa aliquip occaecat. Ut cillum duis nostrud non et qui duis officia. Sit voluptate elit culpa incididunt elit irure ad labore deserunt veniam ipsum minim voluptate. Veniam laboris aliquip eu exercitation anim aute.",
    },
    {
      id: 3,
      company: "Alino",
      name: "Akachi wen",
      review: 5,
      designation: "Software developer",
      profile_picture:
        "https://images.unsplash.com/photo-1531123897727-8f129e1688ce?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      text: "Magna minim est excepteur labore do pariatur et non. Excepteur elit laboris commodo esse pariatur. Labore ullamco veniam qui veniam non. Lorem Lorem quis fugiat id minim irure nulla cupidatat do reprehenderit. Ex sunt incididunt deserunt ullamco exercitation fugiat labore eu exercitation labore. Magna nulla ex eiusmod ad nisi amet officia laboris reprehenderit ad consequat. Excepteur eiusmod ut magna officia incididunt id commodo non excepteur officia enim duis eiusmod.  Duis labore esse sunt esse quis minim deserunt fugiat qui ipsum. Dolore reprehenderit mollit ea anim occaecat excepteur enim ex pariatur cupidatat. Aliqua deserunt et amet eu nulla eu culpa eu laboris culpa aliquip occaecat. Ut cillum duis nostrud non et qui duis officia. Sit voluptate elit culpa incididunt elit irure ad labore deserunt veniam ipsum minim voluptate. Veniam laboris aliquip eu exercitation anim aute.",
    },
    {
      id: 4,
      company: "Semlui",
      name: "Stefan stefino",
      review: 5,
      designation: "Software developer",
      profile_picture:
        "https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80",
      text: "Magna minim est excepteur labore do pariatur et non. Excepteur elit laboris commodo esse pariatur. Labore ullamco veniam qui veniam non. Lorem Lorem quis fugiat id minim irure nulla cupidatat do reprehenderit. Ex sunt incididunt deserunt ullamco exercitation fugiat labore eu exercitation labore. Magna nulla ex eiusmod ad nisi amet officia laboris reprehenderit ad consequat. Excepteur eiusmod ut magna officia incididunt id commodo non excepteur officia enim duis eiusmod.  Duis labore esse sunt esse quis minim deserunt fugiat qui ipsum. Dolore reprehenderit mollit ea anim occaecat excepteur enim ex pariatur cupidatat. Aliqua deserunt et amet eu nulla eu culpa eu laboris culpa aliquip occaecat. Ut cillum duis nostrud non et qui duis officia. Sit voluptate elit culpa incididunt elit irure ad labore deserunt veniam ipsum minim voluptate. Veniam laboris aliquip eu exercitation anim aute.",
    },
    {
      id: 5,
      company: "Algora",
      review: 5,
      designation: "Software developer",
      profile_picture:
        "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEyfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60",
      text: "Magna minim est excepteur labore do pariatur et non. Excepteur elit laboris commodo esse pariatur. Labore ullamco veniam qui veniam non. Lorem Lorem quis fugiat id minim irure nulla cupidatat do reprehenderit. Ex sunt incididunt deserunt ullamco exercitation fugiat labore eu exercitation labore. Magna nulla ex eiusmod ad nisi amet officia laboris reprehenderit ad consequat. Excepteur eiusmod ut magna officia incididunt id commodo non excepteur officia enim duis eiusmod.  Duis labore esse sunt esse quis minim deserunt fugiat qui ipsum. Dolore reprehenderit mollit ea anim occaecat excepteur enim ex pariatur cupidatat. Aliqua deserunt et amet eu nulla eu culpa eu laboris culpa aliquip occaecat. Ut cillum duis nostrud non et qui duis officia. Sit voluptate elit culpa incididunt elit irure ad labore deserunt veniam ipsum minim voluptate. Veniam laboris aliquip eu exercitation anim aute.",
    },
    {
      id: 6,
      company: "SS numaier",
      review: 5,
      profile_picture:
        "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80",
      designation: "Network engineer",
      text: "Magna minim est excepteur labore do pariatur et non. Excepteur elit laboris commodo esse pariatur. Labore ullamco veniam qui veniam non. Lorem Lorem quis fugiat id minim irure nulla cupidatat do reprehenderit. Ex sunt incididunt deserunt ullamco exercitation fugiat labore eu exercitation labore. Magna nulla ex eiusmod ad nisi amet officia laboris reprehenderit ad consequat. Excepteur eiusmod ut magna officia incididunt id commodo non excepteur officia enim duis eiusmod.  Duis labore esse sunt esse quis minim deserunt fugiat qui ipsum. Dolore reprehenderit mollit ea anim occaecat excepteur enim ex pariatur cupidatat. Aliqua deserunt et amet eu nulla eu culpa eu laboris culpa aliquip occaecat. Ut cillum duis nostrud non et qui duis officia. Sit voluptate elit culpa incididunt elit irure ad labore deserunt veniam ipsum minim voluptate. Veniam laboris aliquip eu exercitation anim aute.",
    },
    {
      id: 7,
      company: "Net & co",
      review: 5,
      profile_picture:
        "https://images.unsplash.com/photo-1506863530036-1efeddceb993?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1044&q=80",
      designation: "Tech lead",
      text: "Magna minim est excepteur labore do pariatur et non. Excepteur elit laboris commodo esse pariatur. Labore ullamco veniam qui veniam non. Lorem Lorem quis fugiat id minim irure nulla cupidatat do reprehenderit. Ex sunt incididunt deserunt ullamco exercitation fugiat labore eu exercitation labore. Magna nulla ex eiusmod ad nisi amet officia laboris reprehenderit ad consequat. Excepteur eiusmod ut magna officia incididunt id commodo non excepteur officia enim duis eiusmod.  Duis labore esse sunt esse quis minim deserunt fugiat qui ipsum. Dolore reprehenderit mollit ea anim occaecat excepteur enim ex pariatur cupidatat. Aliqua deserunt et amet eu nulla eu culpa eu laboris culpa aliquip occaecat. Ut cillum duis nostrud non et qui duis officia. Sit voluptate elit culpa incididunt elit irure ad labore deserunt veniam ipsum minim voluptate. Veniam laboris aliquip eu exercitation anim aute.",
    },
    {
      id: 8,
      company: "Satosi tech",
      name: "Stefani liverani",
      review: 5,
      profile_picture:
        "https://images.unsplash.com/photo-1567532939604-b6b5b0db2604?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      designation: "Devops engineer",
      text: "Magna minim est excepteur labore do pariatur et non. Excepteur elit laboris commodo esse pariatur. Labore ullamco veniam qui veniam non. Lorem Lorem quis fugiat id minim irure nulla cupidatat do reprehenderit. Ex sunt incididunt deserunt ullamco exercitation fugiat labore eu exercitation labore. Magna nulla ex eiusmod ad nisi amet officia laboris reprehenderit ad consequat. Excepteur eiusmod ut magna officia incididunt id commodo non excepteur officia enim duis eiusmod.  Duis labore esse sunt esse quis minim deserunt fugiat qui ipsum. Dolore reprehenderit mollit ea anim occaecat excepteur enim ex pariatur cupidatat. Aliqua deserunt et amet eu nulla eu culpa eu laboris culpa aliquip occaecat. Ut cillum duis nostrud non et qui duis officia. Sit voluptate elit culpa incididunt elit irure ad labore deserunt veniam ipsum minim voluptate. Veniam laboris aliquip eu exercitation anim aute.",
    },
    {
      id: 9,
      company: "AT & T",
      name: "Austin wade",
      review: 5,
      profile_picture:
        "https://images.unsplash.com/photo-1552374196-c4e7ffc6e126?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
      designation: "Software developer intern",
      text: "Magna minim est excepteur labore do pariatur et non. Excepteur elit laboris commodo esse pariatur. Labore ullamco veniam qui veniam non. Lorem Lorem quis fugiat id minim irure nulla cupidatat do reprehenderit. Ex sunt incididunt deserunt ullamco exercitation fugiat labore eu exercitation labore. Magna nulla ex eiusmod ad nisi amet officia laboris reprehenderit ad consequat. Excepteur eiusmod ut magna officia incididunt id commodo non excepteur officia enim duis eiusmod.  Duis labore esse sunt esse quis minim deserunt fugiat qui ipsum. Dolore reprehenderit mollit ea anim occaecat excepteur enim ex pariatur cupidatat. Aliqua deserunt et amet eu nulla eu culpa eu laboris culpa aliquip occaecat. Ut cillum duis nostrud non et qui duis officia. Sit voluptate elit culpa incididunt elit irure ad labore deserunt veniam ipsum minim voluptate. Veniam laboris aliquip eu exercitation anim aute.",
    },
    {
      id: 10,
      company: "Base mobile",
      name: "jack finningan",
      review: 5,
      designation: "network engineer",
      profile_picture:
        "https://images.unsplash.com/photo-1528892952291-009c663ce843?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=644&q=80",
      text: "Magna minim est excepteur labore do pariatur et non. Excepteur elit laboris commodo esse pariatur. Labore ullamco veniam qui veniam non. Lorem Lorem quis fugiat id minim irure nulla cupidatat do reprehenderit. Ex sunt incididunt deserunt ullamco exercitation fugiat labore eu exercitation labore. Magna nulla ex eiusmod ad nisi amet officia laboris reprehenderit ad consequat. Excepteur eiusmod ut magna officia incididunt id commodo non excepteur officia enim duis eiusmod.  Duis labore esse sunt esse quis minim deserunt fugiat qui ipsum. Dolore reprehenderit mollit ea anim occaecat excepteur enim ex pariatur cupidatat. Aliqua deserunt et amet eu nulla eu culpa eu laboris culpa aliquip occaecat. Ut cillum duis nostrud non et qui duis officia. Sit voluptate elit culpa incididunt elit irure ad labore deserunt veniam ipsum minim voluptate. Veniam laboris aliquip eu exercitation anim aute.",
    },
  ],
};

export default mock_data;
