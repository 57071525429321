import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { ArrowLeft } from "react-feather";
import { Avatar, CircularProgress, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import Lottie from "react-lottie";
import leaderBoardMedal from "../utilities/lotties/leaderboard-medal.json";


const medalDefaultOptions = {
  loop: false,
  autoplay: true,
  animationData: leaderBoardMedal,
  rendererSettings: {
    preserveAspectRatio: "xMidyMid slice",
  },
};
const LeaderboardDrawer = (props) => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { open, toggleLeaderboard, getLeaderboardData, test_id, userId } =
    props;

  const [loader, setLoader] = useState(true);
  const [rankers, setRankers] = useState([]);

  const callApi = async () => {
    if (!open) return;
    setLoader(true);
    const resp = await getLeaderboardData(test_id)();
    setRankers(resp || []);
    setLoader(false);
  };

  useEffect(() => {
    callApi();
  }, [open]);

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={open}
      onClose={() => toggleLeaderboard()}
      onOpen={() => toggleLeaderboard()}
    >
      {loader ? (
        <Box
          sx={{ width: smScreen ? "100vw" : "30vw", padding: "0 2rem 0 3rem" }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ height: "90vh" }}
        >
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Box
          sx={{ width: smScreen ? "100vw" : "30vw", padding: "0 2rem 0 3rem" }}
          role="presentation"
          onClick={() => toggleLeaderboard()}
          onKeyDown={() => toggleLeaderboard()}
        >
          <div
            className="flex flex-col"
            style={{
              paddingTop: "6rem",
              width: smScreen? "95%": "85%",
              alignSelf: "center",
            }}
          >
            <div
              className="flex flex-row"
              style={{
                marginLeft: "-0.8rem",
              }}
            >
              <ArrowLeft
                size={30}
                color="#777"
                className="cursor-pointer"
                style={{}}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleLeaderboard();
                }}
              />
              <h4
              className="black"
              style={{
                fontFamily: "Poppins",
                fontSize: "1.4rem",
                // padding: "1rem 0 .6rem 0",
                paddingLeft: "1rem",
                fontWeight: 800,
              }}
            >
              Leaderboard
            </h4>
            </div>
            <div>
              {rankers?.length > 0 ? (
                <div
                  className="text-slate-400 font-semibold mb-6 uppercase"
                  style={{
                    fontSize: ".8rem",
                  }}
                >
                  {rankers[0]?.test?.name}
                </div>
              ) : (
                <div className="flex justify-center items-center">
                  <h4 className="text-slate-500 font-semibold">
                    Leaderboard for this test not available currently.
                  </h4>
                </div>
              )}
            </div>
            {rankers?.map((ranker, index) => (
              <div
                className="flex w-full h-full mb-2"
                key={index}
                style={{
                  padding: "12px 0",
                  backgroundColor: ranker.user?._id === userId ? "#FEFBF6" : "",
                  borderRadius: "12px",
                }}
              >
                <div
                  className="flex items-center w-1/6 justify-center mb-1"
                  style={{
                    width: "3rem",
                  }}
                >
                  {ranker.rank < 3 ? (        
                    <div className="flex justify-start">
                    <Lottie
                    options={medalDefaultOptions}
                    style={{
                      width: "50%",
                      height: "50%",
                      marginRight: "5px",
                    }}
                  /> </div>
                  ) : (
                    <div>#{ranker.rank}</div>
                  )}
                </div>
                <div className="flex w-1/6 justify-center mr-3">
                  <Avatar
                    src={ranker?.user?.profile_picture}
                    variant="rounded"
                    style={{
                      border: "4px solid #fcfcfc",
                      width: "3rem",
                      height: "3rem",
                      backgroundColor: "#0CCBFF",
                      fontSize: "1rem",
                      zIndex: 2,
                      borderRadius: "8px",
                      fontWeight: 700,
                    }}
                  >
                    {ranker?.user?.name?.charAt(0)?.toUpperCase()}
                  </Avatar>
                </div>
                <div className="flex flex-col w-4/6">
                  <h4
                    className="text-slate-600 font-semibold  capitalize flex items-center"
                    style={{
                      fontFamily: "Poppins",
                    }}
                  >
                    {ranker?.user?.name}{" "}
                    {ranker?.user?._id === userId && (
                      <span
                        style={{
                          fontSize: ".8rem",
                          marginLeft: ".2rem",
                        }}
                      >
                        (You)
                      </span>
                    )}
                  </h4>
                  <div
                    className="text-slate-500 font-semibold"
                    style={{
                      fontSize: ".9rem",
                      fontFamily: "Roboto",
                      fontWeight: 700,
                    }}
                  >
                    score: {ranker?.percent}%
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Box>
      )}
    </SwipeableDrawer>
  );
};

export default LeaderboardDrawer;
