const initialState = {
  theme: "LIGHT",
};

const preferenceReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default preferenceReducer;
