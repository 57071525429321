import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { ChevronLeft, Eye, EyeOff, GitHub } from "react-feather";
import { useHistory, useLocation } from "react-router-dom";
import { withFormik } from "formik";
import { connect } from "react-redux";
import Lottie from "react-lottie";
import * as Yup from "yup";

import confetti from "../utilities/lotties/confetti.json";
import dots from "../utilities/lotties/dots.json";
import { setProfile, signin } from "../store/actions/authActions";
import { toggleSnakebar } from "../store/actions";

const useStyles = makeStyles((theme) => ({
  container: {},
  box1: {
    order: 1,
  },
  box2: {
    order: 0,
  },
  move: {},
  header: {
    fontSize: "1rem",
    fontWeight: "500",
    fontFamily: "Raleway",
    margin: "0 .5rem",
    color: "#fffff",
  },
}));

const infographics = [
  {
    img: "cloud_hosting",
    text: "Practice your skills to get ready for your next exam",
  },
  { img: "practice_env", text: "The perfect practice environment" },
  { img: "blooming", text: "Move your tech journey forward" },
  {
    img: "check",
    text: "Master your domain, ace the certification exams",
  },
  {
    img: "track_progress",
    text: "Get the experience of actual test environment",
  },
];

const LoginPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [showPass, setShowPass] = useState(false);
  const [selected, setSelected] = useState(0);
  const [int, setInt] = useState(null);
  const [authLoader, setAuthLoader] = useState(false);

  const registered = history?.location?.state?.registered;

  const {
    values,
    errors,
    touched,
    setErrors,
    setTouched,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,

    setProfile,
    isAuthenticated,
  } = props;

  const toggleShowPass = () => {
    setShowPass((state) => !state);
  };

  const authenticateUser = async (token) => {
    const query = new URLSearchParams(location.search);
    const email = query.get("email");

    setAuthLoader(true);
    const profile = await setProfile(token);
    if (!profile?.verified) {
      history.push("/verify", {
        email,
      });
    }
    setAuthLoader(false);
  };

  const startCorousel = () => {
    const inte = setInterval(() => {
      setSelected((selected) => (selected + 1) % infographics.length);
    }, 4000);
    setInt(inte);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get("token");
    if (token) authenticateUser(token);
  }, [location?.search]);

  useEffect(() => {
    setSelected(0);
    if (!int) {
      startCorousel();
    }
    return () => {
      if (int) clearInterval(int);
    };
  }, []);

  if (isAuthenticated) {
    history.replace("/app/profile");
    return null;
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: confetti,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: dots,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      className={`flex w-screen h-screen overflow-hidden transition-all ease-in-out bg-blue-500
       ${classes.container}`}
    >
      <div
        className={`flex flex-col w-full md:w-1/2 justify-center items-center content-center bg-white ${classes.box1}`}
      >
        <div className="w-full flex h-14 justify-start items-center p-2 md:p-8">
          <ChevronLeft
            size={22}
            color="#888"
            className="mr-2 cursor-pointer"
            onClick={() => history.push("/")}
            style={{
              zIndex: 1401,
            }}
          />
          <img
            src="/images/logo.png"
            width="30"
            height="30"
            style={{
              zIndex: 1400,
            }}
            data-aos="zoom-in"
            data-aos-delay={200}
          />
          <span className={classes.header} data-aos="slide-left">
            Welcome Back
          </span>
        </div>
        <div
          className="h-full flex flex-col w-4/5 self-center items-center justify-center md:items-start py-14 pb-60 md:pb-14"
          data-aos="fade-left"
        >
          <h4 className="text-2xl font-semibold text-slate-700 mb-6">
            Sign In
          </h4>
          {!!registered && (
            <div
              style={{
                borderRadius: "6px",
                padding: "4px 1rem",
                border: "1px solid #328a56",
                backgroundColor: "#EDF6EC",
                margin: "6px 0 1rem 0",
              }}
            >
              {registered
                ? "Registration successful. Login to your account."
                : ""}
            </div>
          )}
          <div className="w-full md:w-4/6 mb-6">
            <TextField
              placeholder="john@gmail.com"
              type="email"
              label="Email"
              fullWidth
              size="small"
              variant="outlined"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched?.email && errors?.email}
              helperText={touched?.email && errors?.email}
              required={true}
              disabled={values.submitting}
              value={values.email}
            />
          </div>
          <div className="w-full md:w-4/6 mb-6">
            <TextField
              type={showPass ? "text" : "password"}
              label="Password"
              size="small"
              required={true}
              variant="outlined"
              fullWidth
              value={values.password}
              error={touched?.password && errors?.password}
              onBlur={handleBlur}
              helperText={touched?.password && errors?.password}
              name="password"
              disabled={values.submitting}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="cursor-pointer">
                    {showPass ? (
                      <Eye size={20} onClick={toggleShowPass} />
                    ) : (
                      <EyeOff size={20} onClick={toggleShowPass} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="w-full md:w-4/6 mt-4">
            <Button
              variant="contained"
              fullWidth
              size="small"
              style={{
                background: "dodgerblue",
                padding: "8px 0",
                fontWeight: "600",
                borderRadius: ".9rem",
              }}
              data-aos="zoom-in"
              data-aos-offset={40}
              data-aos-once={true}
              onClick={(e) =>
                !authLoader && !values.submitting && handleSubmit(e)
              }
            >
              {authLoader || values.submitting ? (
                <CircularProgress
                  size={20}
                  style={{
                    color: "white",
                  }}
                />
              ) : (
                "SIGN IN"
              )}
            </Button>
          </div>
          <div className="flex items-center justify-center w-full md:w-4/6 py-6">
            <hr
              className="w-2/5"
              data-aos="slide-left"
              data-aos-delay={500}
              data-aos-offset={40}
              data-aos-once={true}
            />
            <span className="px-8">or</span>
            <hr
              className="w-2/5"
              data-aos="slide-left"
              data-aos-once={true}
              data-aos-offset={40}
              data-aos-delay={500}
            />
          </div>
          <div className="flex flex-col md:flex-row items-center w-full md:w-4/6">
            <div
              className="flex w-full rounded-md bg-slate-100 p-2 items-center justify-center md:mr-4 md:mb-0 mb-4 cursor-pointer hover:bg-slate-200 transition-all ease-in-out flex-1"
              data-aos="zoom-in"
              data-aos-once={true}
              data-aos-offset={40}
              onClick={() =>
                window.open(
                  process.env.REACT_APP_BACKEND_URL + "/auth/github",
                  "_self"
                )
              }
            >
              <GitHub size={20} color="#444" strokeWidth={2.5} />
              <span className="pl-2 items-center flex text-slate-600 font-semibold text-14">
                Login with Github
              </span>
            </div>
            <div
              className="flex w-full rounded-md bg-slate-100 p-2 items-center justify-center cursor-pointer  hover:bg-slate-200 transition-all ease-in-out flex-1"
              data-aos="zoom-in"
              data-aos-once={true}
              data-aos-offset={40}
              onClick={() =>
                window.open(
                  process.env.REACT_APP_BACKEND_URL + "/auth/google",
                  "_self"
                )
              }
            >
              <GoogleIcon
                style={{
                  fontSize: "1.2rem",
                  color: "#444",
                }}
              />
              <span className="pl-2 items-center flex text-slate-600 font-semibold text-14">
                Login with Google
              </span>
            </div>
          </div>
          <div className="flex w-full py-8 md:justify-start justify-center text-gray-600">
            Don't have an account?
            <span
              className="text-blue-600 font-bold px-2 pl-4 hover:text-blue-500 cursor-pointer"
              onClick={() => history.push("/register")}
            >
              Signup
            </span>{" "}
            <span className="hidden md:block">instead</span>
          </div>
        </div>
      </div>
      <div
        className={`flex justify-center items-center flex-col w-1/2 bg-blue-500 md:flex h-full ${classes.box2} relative md:block hidden`}
        data-aos="slide-left"
      >
        <div
          style={{
            position: "fixed",
            width: "60vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <Lottie
            options={defaultOptions}
            style={{
              width: "82%",
              height: "80%",
            }}
          />
        </div>
        <img
          src={`/images/${infographics[selected].img}.svg`}
          style={{
            width: "40%",
            height: "40%",
            transition: "all ease-in-out 1s",
            zIndex: 500,
          }}
          data-aos="slide-right"
          data-aos-delay={1000}
        />
        <div
          className="flex justify-center items-center w-full text-white font-semibold my-4"
          style={{
            fontFamily: "Raleway",
            zIndex: 500,
          }}
          data-aos="fade-in"
        >
          {infographics[selected].text}
        </div>
      </div>
    </div>
  );
};

const LoginPageEnhanced = withFormik({
  mapPropsToValues: () => ({ email: "", password: "", submitting: false }),

  validationSchema: Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().min(8).max(40).required("Enter your password"),
  }),

  handleSubmit: async (values, { props, setSubmitting, setFieldValue }) => {
    try {
      setFieldValue("submitting", true);
      const { email, password } = values;
      const { signin, history, toggleSnakebar } = props;
      const loggedIn = await signin({
        email,
        password,
      });
      if (loggedIn.success) {
        return history.replace(
          loggedIn.verified ? "/app/profile" : `/verify?email=${email}`,
          { email }
        );
      }
      toggleSnakebar(true, loggedIn?.error, "error");
      setFieldValue("submitting", false);
    } catch (error) {
      setFieldValue("submitting", false);
    }
  },

  displayName: "LoginPageForm",
})(LoginPage);

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth?.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signin: (payload) => dispatch(signin(payload)),
    setProfile: (token) => dispatch(setProfile(token)),
    toggleSnakebar: (show, msg, severity) =>
      dispatch(toggleSnakebar(show, msg, severity)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageEnhanced);
