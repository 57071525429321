import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { ChevronLeft } from "react-feather";
import { Tooltip } from "@mui/material";
import Card from "../components/Card";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "#f1f1fd",
    overflow: "auto",
  },
  header: {
    width: "100%",
    display: "flex",
    padding: "8px 12px",
    height: "3rem",
    transition: "all ease-in-out .2s",
    boxShadow: "0px -8px 18px 0px #aaa",
    position: "fixed",
    zIndex: 1500,
  },
  back: {
    cursor: "pointer",
    transition: "all ease-in-out .2s",
    "&:hover": {
      paddingRight: ".2rem",
    },
  },
  textbold: {
    fontSize: "1.8rem",
  },
}));

const CourseList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [scrolled, setScrolled] = useState(false);
  const category = props?.match?.params?.cat || "";

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      setScrolled(window.scrollY > 50);
    },
    [scrolled]
  );

  useEffect(() => {
    setScrolled(window.scrollY > 50);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <div className={classes.container}>
      <div
        className={`${classes.header} bg-prime_light1 items-center transition-all ease-in-out`}
      >
        <div className="flex items-center justify-start w-1/5">
          <Tooltip title="homepage">
            <ChevronLeft
              size={32}
              color="#aaa"
              className={classes.back}
              onClick={() => history.push("/")}
            />
          </Tooltip>
          {scrolled && (
            <span
              className="pl-3 font-bold text-slate-800"
              data-aos="slide-up"
              data-aos-duration="200"
            >
              {category}
            </span>
          )}
        </div>
        <div className="flex justify-center items-center w-3/5">
          <img
            src="/images/logo.png"
            alt="delvex"
            className={classes.logo}
            width="30"
            height="30"
          />
          <span className="ml-2">DelveX</span>
        </div>
        <div className="flex items-center justify-end w-1/5"></div>
      </div>
      <div className="flex w-full justify-start items-center px-12 pt-16">
        <h4 className={`text-slate-600 font-bold ${classes.textbold}`}>
          {category}
        </h4>
      </div>
      <div className="flex w-full h-full flex-wrap px-12 py-4 gap-9 overflow-hidden">
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </div>
    </div>
  );
};

export default CourseList;
