import { TOGGLE_SNAKEBAR } from "../actions/actionTypes";

const initialState = {
  show: false,
  msg: null,
  severity: "info",
};

const snakebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SNAKEBAR: {
      const { show, msg, severity } = action.payload;
      return {
        ...state,
        show,
        msg: show ? msg : state.msg,
        severity: show ? severity : state.severity,
      };
    }
    default:
      return state;
  }
};

export default snakebarReducer;
