import axios from "../../utilities/axios";
import {
  LOAD_TEST,
  RESET_TEST,
  NEXT_QUESTION,
  LOAD_QUESTION,
  QUIT_TEST,
} from "./actionTypes";

const checkStatus = (data) => {
  const error = data.error;
  const failed = data.failed;
  const incorrect = !data.correct;

  if (error) return "error";
  if (failed) return "failed";
  if (incorrect) return "incorrect";
  return "correct";
};

export const loadTest = (levelId, testId) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const token = localStorage.getItem("token");
      const resp = await axios.get("/test/load_test/" + levelId, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      const {
        currentQuestionId,
        question,
        options,
        totalQuestions,
        isLast,
        qNumber,
        test,
        testId,
        msq,
        level,
        startedAt,
        duration,
      } = resp.data;

      dispatch({
        type: LOAD_TEST,
        payload: {
          levelId,
          testId,
          currentQuestionId,
          question,
          options,
          isLast,
          msq,
          totalQuestions,
          qNumber,
          testName: test,
          levelName: level,
          startedAt,
          duration,
        },
      });
      return { loaded: true };
    } catch (error) {
      return { loaded: false, error: error?.response?.data?.message || false };
    }
  };
};

export const submitAnswer = (answer, skipped) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { currentQuestionId, levelId, totalQuestions, isLast } =
        state.liveTest;
      const token = localStorage.getItem("token");
      const submitted = await axios.post(
        "/test/next_question",
        {
          levelId,
          currentQuestionId,
          answer,
          skipped,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      const status = checkStatus(submitted.data);
      switch (status) {
        case "error": {
        }
        case "failed": {
          const { snapshotId } = submitted.data;

          dispatch({
            type: NEXT_QUESTION,
            payload: {
              snapshotId,
              prevQuestionSelected: skipped ? -1 : answer,
            },
          });

          return;
        }
        case "incorrect": {
          const {
            currentQuestionId: questionId,
            question,
            options,
            qNumber,
            isLast,
            testComplete,
            correct,
            snapshotId,
            msq,
          } = submitted.data;

          dispatch({
            type: NEXT_QUESTION,
            payload: {
              currentQuestionId: questionId,
              correct,
              question,
              options,
              qNumber,
              isLast,
              testComplete,
              snapshotId,
              prevQuestionSelected: skipped ? -1 : answer,
              msq,
            },
          });
          return;
        }
        case "correct": {
          const {
            currentQuestionId: questionId,
            question,
            options,
            qNumber,
            isLast,
            testComplete,
            correct,
            snapshotId,
            msq,
          } = submitted.data;

          dispatch({
            type: NEXT_QUESTION,
            payload: {
              currentQuestionId: questionId,
              correct,
              question,
              options,
              qNumber,
              isLast,
              testComplete,
              snapshotId,
              prevQuestionSelected: skipped ? -1 : answer,
              msq,
            },
          });
          return;
        }
      }
    } catch (error) {
      return false;
    }
  };
};

export const getTestReport = (report_id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const report = await axios.get("/test/report/" + report_id, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: RESET_TEST,
      });
      return report?.data;
    } catch (error) {
      return false;
    }
  };
};

export const quitTest = (levelId) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const resp = await axios.post(
        "/test/submit",
        {
          levelId,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: QUIT_TEST,
        payload: {
          snapshotId: resp.data.snapshotId,
        },
      });
      return resp.data.snapshotId;
    } catch (error) {
      return false;
    }
  };
};

export const resetTest = () => {
  return async (dispatch) => {
    try {
    } catch (error) {
      return false;
    }
  };
};

export const loadQuestion = (questionNumber) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const question = state.liveTest.questions[questionNumber - 1];
      dispatch({
        type: LOAD_QUESTION,
        payload: {
          questionNumber,
        },
      });
      return question.selected;
    } catch (error) {
      return false;
    }
  };
};
