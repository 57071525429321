import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";

import HeaderIn from "../components/headerIn";
import ProfilePage from "./profile";
import ExamListPage from "./examListPage";
import ExamDetailPage from "./examDetailPage";
import QuestionPage from "./questionPage";
import TestReportPage from "./testReportPage";
import LevelListPage from "./levelListPage";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#fcfcfc",
    height: "100vh",
    overflow: "hidden",
  },
  subContainer: {},
}));

const AppLayout = (props) => {
  const classes = useStyles();
  console.log("p: ", props.location)

  const { live } = props;

  useEffect(() => {}, []);

  return (
    <div
      className={`flex w-full ${classes.container}`}
      style={{
        paddingTop: live ? 0 : "4.5rem",
      }}
    >
      {!live && <HeaderIn />}
      <div className={`flex w-full h-full ${classes.subContainer}`}>
        <Switch>
          <Route path="/app" exact>
            <Redirect to="/app/exams" />
          </Route>
          <Route path="/app/profile" component={ProfilePage} exact />
          <Route path="/app/exams" component={ExamListPage} exact />
          <Route path="/app/levels/:id" component={LevelListPage} exact />
          <Route path="/app/exams/:id" component={ExamDetailPage} exact />
          <Route path="/app/exam/:exam_id/" component={QuestionPage} exact />
          <Route
            path="/app/report/:report_id/"
            component={TestReportPage}
            exact
          />
        </Switch>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth?.isAuthenticated,
    live: state.liveTest.live,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
