import React from "react";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { Lock, ArrowRightCircle } from "react-feather";
import Lottie from "react-lottie";
import unlockLottieData from "../utilities/lotties/unlock.json";
import lockLottieData from "../utilities/lotties/lock.json";
const useStyles = makeStyles((theme) => ({
  card: {
    padding: "16px 24px",
    width: "22%",
    height: "100px",
    borderRadius: "10px",
    margin: "16px",
    boxSizing: "border-box",
    marginBottom: "-10px",
    background: "white",
    [theme.breakpoints.down("md")]: {
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const unlockDefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: unlockLottieData,
  rendererSettings: {
    preserveAspectRatio: "xMidyMid slice",
    // margin: "0px 20px 0px 0px",
  },
  // container: true,
};

const lockDefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: lockLottieData,
  rendererSettings: {
    preserveAspectRatio: "xMidyMid slice",
    // margin: "0px 20px 0px 0px",
  },
  // container: true,
};
const colors = [
  "rgba(241, 90, 36, 0.08)",
  "rgba(82, 39, 133, 0.08)",
  "rgba(232, 42, 123, 0.08)",
  "rgba(42, 232, 23, 0.08)",
  "rgba(92, 232, 23, 0.08)",
];

const MiniCard = (props) => {
  const {
    title,
    sidebar,
    customStyles,
    id,
    questions,
    attempted,
    unlock,
    toggleSnakebar,
  } = props;
  const history = useHistory();

  const classes = useStyles({ sidebar });

  return (
    <div
      className={`flex ${classes.card} cursor-pointer mb-2 hover:shadow-lg transition-all ease-in-out hover:scale-105 shadow-sm`}
      onClick={() => {
        if (!unlock)
          toggleSnakebar(
            true,
            "You don't have access to this level. complete previous levels first",
            "info"
          );
        else history.push(`/app/exams/${id}`);
      }}
      style={{
        borderLeft: unlock ? ".6rem solid #2ECC71" : ".6rem solid #E74C3C"
      }}
    >
      <div className="flex flex-col justify-center items-start capitalize truncate w-full flex-1">
        <div className="flex w-full justify-between items-center">
          <div
            className="truncate"
            style={{
              fontFamily: "Poppins",
              flex: 1,
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "31px",
              margin: "0px 2px",
              width: "100%",
              ...(customStyles ? customStyles : {}),
            }}
          >
            {title}
          </div>
          {/* <div className="flex items-center flex-end">
            {unlock ? (
              <ArrowRightCircle size={20} color="#aaa" />
            ) : (
              <Lock size={18} color="#aaa" />
            )}
          </div> */}
          <div className="flex items-center flex-end" style={{ width: "30%" }}>
            {unlock ? (
              <div className="flex justify-end">
              <Lottie
              options={unlockDefaultOptions}
              style={{
                width: "50%",
                height: "50%",
                marginRight: "5px",
              }}
            /> </div>) : ( <div className="flex justify-end">
            <Lottie
            options={lockDefaultOptions}
            style={{
              width: "70%",
              height: "70%",
              marginRight: "5px",

            }}
          /> </div>)}
          </div>
        </div>
        <div className="mt-1">
          {questions} question{questions?.length > 1 ? "s" : ""}
        </div>
      </div>
    </div>
  );
};

export default MiniCard;
