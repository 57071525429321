import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Tooltip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "22rem",
    maxHeight: "28rem",
    flex: "0 1 auto",
    borderRadius: "8px",
    transition: "all ease-in-out .3s",
    "&:hover": {
      boxShadow: "0px 10px 14px 1px #ccc",
      transform: "scale(1.04)",
    },
  },
  textbold: {
    fontSize: "1.8rem",
  },
  text1: {
    fontWeight: "600",
    color: "#333",
    fontSize: "1rem",
    textTransform: "capitalize",
    marginBottom: ".4rem",
  },
  p: {
    fontSize: ".9rem",
    color: "#777",
  },
  chip: {
    background: "#E1EAFA",
    color: "dodgerblue",
    padding: "4px 6px",
    borderRadius: "5px",
    display: "flex",
    fontSize: "12px",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "8px",
    marginBottom: "12px",
  },
}));

const bgColors = [
  "#343F82",
  "#F76655",
  "#1A1818",
  "#35A5C6",
  "#DF562C",
  "#FEE845",
];

const Card = () => {
  const classes = useStyles();

  const [colorIndex, setColorIndex] = useState(
    Math.floor(Math.random() * 10) % bgColors.length
  );

  return (
    <div
      className={`flex flex-col bg-white p-4 overflow-hidden ${classes.container}`}
      data-aos="fade-up"
    >
      <div className="flex w-full justify-start items-center py-4">
        <Avatar
          variant="rounded"
          style={{
            height: "3rem",
            width: "3rem",
            backgroundColor: bgColors[colorIndex],
          }}
        >
          A
        </Avatar>
      </div>
      <h4 className={classes.text1}>AWS devops certified</h4>
      <p className={classes.p}>
        Deserunt et deserunt nostrud ad est cillum duis Lorem deserunt eu.
        Labore velit officia dolore voluptate pariatur commodo do Lorem laborum
        officia.
      </p>
      <div className="flex w-full flex-wrap py-2">
        <span className={classes.chip}>Total 20 Questions</span>
        <span className={classes.chip}>60 minutes</span>
        <span className={classes.chip}>Minimum passing 20%</span>
      </div>
      <div className="flex w-full items-center justify-between">
        <button
          className="text-white font-bold rounded-md px-4 py-2 hover:bg-co_prime ease-in-out cursor-pointer transition-all flex flex-1 justify-center items-center mr-4"
          style={{
            fontSize: ".9rem",
            background: "dodgerblue",
          }}
        >
          Take test
        </button>
        <button
          className="text-gray-500 bg-slate-100 font-bold rounded-md px-4 py-2 ease-in-out cursor-pointer transition-all flex flex-1 justify-center items-center"
          style={{
            background: "#f1f1f0",
            fontSize: ".9rem",
          }}
        >
          Leaderboard
        </button>
      </div>
    </div>
  );
};

export default Card;
