import { combineReducers } from "redux";

import auth from "./authReducer";
import preferenceReducer from "./preferenceReducer";
import snakebarReducer from "./snakebarReducer";
import liveTestReducer from "./liveTestReducer";

const rootReducer = combineReducers({
  auth,
  snakebar: snakebarReducer,
  preferences: preferenceReducer,
  liveTest: liveTestReducer,
});

export default rootReducer;
