import React, { useState, useEffect, Fragment } from "react";
import { CircularProgress, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { useHistory } from "react-router";

import { getCategories, getTests } from "../store/actions/exam.action";


//Mauve 
const mauveBase = "#42275a";
const mauveLight = "#734b6d";

//Love & Liberty 
const libertyBase = "#200122";
const liberyLight = "#6f0000";


//Lawrencium
const lawrenciumLight = "#0f0c29";
const lawrenciumBase = "#302b63";
const lawrenciumDark = "#24243e";

//Moonlit
const moonlitLight = "#2c5364";
const moonlitBase = "#0F2027";
const moonlitDark = "#203A43";

//Frost
const frostLight = "#000428";
const frostDark = "#004e92";


//Royal
const royalLight = "#141E30";
const royalDark = "#243B55";

const gradients = [
  `linear-gradient(to bottom right, ${royalLight}, ${royalDark})`,
  `linear-gradient(to bottom right, ${frostLight}, ${frostDark})`,
  `linear-gradient(to top left, ${moonlitLight}, ${moonlitBase}, ${moonlitDark})`,
  `linear-gradient(to top left, ${lawrenciumBase}, ${lawrenciumLight}, ${lawrenciumDark})`,
  `linear-gradient(to bottom right, ${libertyBase}, ${liberyLight})`,
  `linear-gradient(to bottom right, ${mauveBase}, ${mauveLight})`,

];

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    transition: "all ease-in-out .2s",
    height: "22rem",
    width: "20rem",
    borderRadius: "1.4rem",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  title: {
    fontSize: "1.4rem",
    paddingLeft: ".8rem",
    fontWeight: "800",
    fontFamily: "Poppins"
  },
}));

const TestCard = (props) => {
  const history = useHistory();
  const { test, index } = props;
  const { _id, name, category, levels, details } = test;

  const classes = useStyles();
  // const colorCode = colors[index % colors.length];

  const randomIndex = Math.floor(Math.random() * gradients.length);

  return (
    <div
      onClick={() =>
        history.push(`/app/levels/${_id}`, {
          title: name,
        })
      }
    >
      <div  
        className={`flex flex-col p-8 m-2 ${classes.card}`}
        style={{
          background: gradients[randomIndex],
          height: "22rem",
          width: "20rem",
          borderRadius: "1.4rem",
        }}
      >
        <div className={`flex w-full items-center bg-blue`}>
          <div
            className="flex w-3/4 h-8 flex-start text-12 text-white font-bold flex-1 uppercase"
            // data-aos="fade-right"
          >
            {category?.name}
          </div>
        </div>
        <div className="flex flex-col w-full h-full">
          <h2 className="font-semibold text-2xl text-white my-2 uppercase">{name}</h2>
          <div className="w-full h-28 overflow-hidden text-14 text-white">
            {details?.length > 100
              ? details?.substr(0, 100) + "..."
              : details || ""}
          </div>
          <div>
            <div>
              <span className={`font-semibold text-white`}>
                {levels?.length || 0}&nbsp;
              </span>
              <span className={`font-semibold text-white`}>Levels</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const ExamListPage = (props) => {
  const classes = useStyles();

  const { getCategories, getTests } = props;

  const [categories, setCategories] = useState([]);
  const [tests, setTests] = useState([]);
  const [loader, setLoader] = useState(true);

  const callApi = async () => {
    setLoader(true);
    const list = await getCategories();
    const testList = await getTests();
    setTests(testList || {});
    setCategories(list || []);
    setLoader(false);
  };

  useEffect(() => {
    callApi();
  }, []);

  return (
    <div className="flex flex-col w-full h-full overflow-auto px-4">
      {loader ? (
        <div
          className="flex min-w-full justify-center items-center"
          style={{
            height: "70vh",
          }}
        >
          <CircularProgress size={32} />
        </div>
      ) : !Object.keys(tests)?.length ? (
        <div className="flex w-full justify-center items-center h-screen">
          <h4
            className="text-slate-600 font-semibold"
            style={{
              fontSize: "1.2rem",
            }}
          >
            Tests are not available currently
          </h4>
        </div>
      ) : (
        <Fragment>
          <div className="flex w-full items-center mb-4">
            <div className="flex w-1/2 items-center">
              {/* <span
                className={`font-semibold text-slate-500 capitalize ${classes.title}`}
              >
                Exams
              </span> */}
            </div>
            <div className="flex w-1/2 items-center justify-end"></div>
          </div>
          <div className="flex flex-col w-full justify-start">
            {Object.keys(tests)
              ?.filter((key) => !!tests[key]?.length)
              ?.map((key) => {
                const testList = tests[key] || [];
                return (
                  <div className="flex flex-col mb-8 w-full">
                    <h2
                      className={`font-semibold text-slate-500 mb-2  ${classes.title} uppercase`}
                      style={
                        {
                          //   fontSize: "1rem",
                        }
                      }
                    >
                      {key}
                    </h2>
                    <div className="flex overflow-auto w-full pb-8">
                      {testList?.map((test, index) => (
                        <TestCard test={test} key={test?._id} index={index} />
                      ))}{" "}
                    </div>
                  </div>
                );
              })}
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTests: () => dispatch(getTests()),
    getCategories: () => dispatch(getCategories()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamListPage);