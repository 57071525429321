import axios from "../../utilities/axios";

export const getGlobalLeaderboard = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const resp = await axios.get(`/leaderboard`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      return false;
    }
  };
};

export const getLeaderboardData = (test_id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const added = await axios.get(
        `/test/getLeaderboardData/?level_id=${test_id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      return added.data;
    } catch (error) {
      return false;
    }
  };
};

export const getTests = (values) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const added = await axios.get("/test?categorized=true", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return added.data?.tests;
    } catch (error) {
      return false;
    }
  };
};

export const getLevels = (testId) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const resp = await axios.get("/test/levels/" + testId, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      return false;
    }
  };
};

export const getLevel = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const resp = await axios.get("/test/level/" + id, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      return false;
    }
  };
};

export const getTest = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const resp = await axios.get("/test/" + id, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return resp.data;
    } catch (error) {
      return false;
    }
  };
};

export const getCategories = (values) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const categories = await axios.get("/category", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return categories?.data?.categories;
    } catch (error) {
      return error;
    }
  };
};

export const getQuestion = (id) => {
  return async (dispatch) => {
    try {
      //   const token = localStorage.getItem("token");
      //   const questions = await axios.get("/test/q/all/" + id, {
      //     headers: {
      //       authorization: `Bearer ${token}`,
      //     },
      //   });
      //   return questions?.data || [];
    } catch (error) {
      return false;
    }
  };
};
