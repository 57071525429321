export const LOGGED_IN = "LOGGED_IN";
export const LOGOUT = "LOGOUT";
export const SET_PROFILE = "SET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const TOGGLE_SNAKEBAR = "TOGGLE_SNAKEBAR";
export const LOAD_TEST = "LOAD_TEST";
export const RESET_TEST = "RESET_TEST";
export const NEXT_QUESTION = "NEXT_QUESTION";
export const LOAD_QUESTION = "LOAD_QUESTION";
export const QUIT_TEST = "QUIT_TEST";
