import {
  LOAD_TEST,
  RESET_TEST,
  NEXT_QUESTION,
  LOAD_QUESTION,
  QUIT_TEST,
} from "../actions/actionTypes";

const initialState = {
  live: false,
  testId: null,
  levelId: null,
  isLast: null,
  prevQuestionId: null,
  currentQuestionId: null,
  question: null,
  options: null,
  loading: false,
  totalQuestions: null,
  currentStatus: [], // -1: attempted & correct; 0: not attempted yet; 1: attempted & correct
  qNumber: null,
  snapshotId: null,
  questions: [],
  currentQuestionNumber: 1,
  displayingQuestion: 1,
  testName: null,
  levelName: null,
  startedAt: null,
  msq: false,
  duration: null,
};

const liveTestReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TEST: {
      const {
        testId,
        levelId,
        currentQuestionId,
        question,
        options,
        totalQuestions,
        isLast,
        qNumber,
        testName,
        levelName,
        startedAt,
        duration,
        msq,
      } = action.payload;

      return {
        ...state,
        live: true,
        testId,
        levelId,
        isLast,
        prevQuestionId: null,
        currentQuestionId,
        question,
        options,
        loading: false,
        totalQuestions,
        currentStatus: Array.from(Array(totalQuestions).keys()).map((_) => 0),
        qNumber,
        questions: [{ question, selected: -1, options }],
        currentQuestionNumber: 1,
        displayingQuestion: 1,
        testName,
        levelName,
        msq,
        startedAt,
        duration,
      };
    }
    case NEXT_QUESTION: {
      const {
        currentQuestionId,
        question,
        options,
        isLast,
        qNumber,
        correct,
        snapshotId,
        msq,
        prevQuestionSelected,
      } = action.payload;

      const updatedStatus = [...state.currentStatus];
      updatedStatus[state.qNumber - 1] = correct ? 1 : -1;

      const updatedQuestionsList = [...state.questions];
      updatedQuestionsList[state.currentQuestionNumber - 1] = {
        question: state.question,
        options: state.options,
        selected: prevQuestionSelected,
      };
      updatedQuestionsList.push({ question, selected: -1, options });

      return {
        ...state,
        isLast,
        prevQuestionId: state.currentQuestionId,
        currentQuestionId,
        question,
        options,
        loading: false,
        currentStatus: updatedStatus,
        qNumber,
        snapshotId,
        questions: updatedQuestionsList,
        msq,
        currentQuestionNumber: state.currentQuestionNumber + 1,
        displayingQuestion: state.displayingQuestion + 1,
      };
    }
    case LOAD_QUESTION: {
      const { questionNumber } = action.payload;

      const questionToBeLoaded = state.questions[questionNumber - 1];

      return {
        ...state,
        options: questionToBeLoaded.options,
        question: questionToBeLoaded.question,
        displayingQuestion: questionNumber,
      };
    }
    case RESET_TEST: {
      return { ...initialState };
    }
    case QUIT_TEST: {
      return { ...initialState, snapshotId: action.payload?.snapshotId };
    }
    default:
      return state;
  }
};

export default liveTestReducer;
