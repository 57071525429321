import { TOGGLE_SNAKEBAR } from "./actionTypes";

export const toggleSnakebar = (show = false, msg, severity = "info") => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TOGGLE_SNAKEBAR,
        payload: {
          show,
          msg,
          severity,
        },
      });
      setTimeout(() => {
        dispatch({
          type: TOGGLE_SNAKEBAR,
          payload: {
            show: false,
          },
        });
      }, 3000);
    } catch (error) {
      return error;
    }
  };
};
