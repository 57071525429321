import { createBrowserHistory } from "history";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import PrivateRoute from "./privateRoute";
import HomePage from "../containers/homepage";
import CourseList from "../containers/courselist";
import RegisterPage from "../containers/registerPage";
import AppLayout from "../containers/appLayout";
import LoginPage from "../containers/loginPage";
import NotFoundPage from "../containers/notFoundPage";
import { useSelector } from "react-redux";
import Snakebar from "../components/Snakebar";
import verifyOTPPage from "../containers/verifyOTPPage";
import LeaderboardPage from "../containers/leaderboardPage";

const history = createBrowserHistory();

const MainRoutes = (props) => {
  const isAuthenticated = useSelector((store) => store.auth?.authenticated);

  return (
    <Router history={history}>
      <Snakebar />
      <Switch>
        <Route path={"/"} component={HomePage} exact />
        <Route path={"/verify"} component={verifyOTPPage} exact />
        <Route path="/auth">
          <Redirect to="/login" />
        </Route>
        <Route
          exact={true}
          path="/register"
          component={RegisterPage}
          key={Math.random()}
          isAuthenticated={isAuthenticated}
        />
        <Route path="/leaderboard" component={LeaderboardPage} exact />
        <Route
          exact={true}
          path="/login"
          component={LoginPage}
          key={Math.random()}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/app"
          component={AppLayout}
          key={Math.random()}
          isAuthenticated={isAuthenticated}
        />
        <Route
          exact={true}
          path="/courses/:cat"
          component={CourseList}
          isAuthenticated={isAuthenticated}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default MainRoutes;
