import React, { useRef, useState } from "react";

import { makeStyles } from "@mui/styles";
import { useHistory, useLocation } from "react-router-dom";
import {
  Avatar,
  Tooltip,
  Grow,
  Popper,
  CircularProgress,
  ClickAwayListener,
} from "@mui/material";
import { connect } from "react-redux";
import { logout } from "../store/actions";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100vw",
    background: "white",
    height: "5.5rem",
    padding: "1rem 2rem",
    borderRadius: "8px",
    zIndex: 1500,
    position: "fixed",
    alignItems: "center",
    alignSelf: "center",
    overflow: "hidden",
    transition: "all ease-in-out .2s",
    top: 0,
    left: 0,
    borderBottom: "1px solid #eee",
    "&:hover": {
      boxShadow: "0px -2px 8px 1px #d9d9d9",
    },
    [theme.breakpoints.down("md")]: {
      height: "4rem",
      padding: ".6rem 1rem",
    },
  },
  icon: {
    display: "flex",
    width: "15%",
    justifyContent: "flex-start",
    fontFamily: "Bebas Neue",
    alignItems: "center",
    fontSize: "1.4rem",
    fontWeight: "500",
    fontFamily: "Bebas Neue",
    letterSpacing: 1.5,
  },
  logo: {
    width: "2.5rem",
    height: "2.5rem",
    marginRight: ".6rem",
  },
  menus: {
    display: "flex",
    width: "50%",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: 1,
    border: "none",
    borderLeft: "1px solid #bbb",
    width: "1px",
    paddingLeft: "1rem",
    [theme.breakpoints.down("md")]: {
      border: "none",
      justifyContent: "flex-end",
    },
  },
  actions: {
    display: "flex",
    width: "25%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  btn: {
    fontSize: "1rem",
    color: "#444",
    fontWeight: "600",
    padding: "8px 12px",
    cursor: "pointer",
    margin: "0 .5rem",
    color: "#522785",
  },
  primary: {
    border: "1px solid #522785",
    borderRadius: "4px",
    background: "#eee9f5",
    transition: "all ease-in-out .1s",
  },
  menu: {
    color: "#555",
    margin: "0 12px",
    fontSize: "0.9rem",
    textTransform: "capitalize",
    cursor: "pointer",
    transition: "all ease-in-out .1s",
    "&:hover": {
      fontWeight: "bold",
      color: "#f26980",
      transform: "scale(1.05)",
    },
  },
  active: {
    color: "#f26980",
    fontWeight: "bold",
  },
  secondaryText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#ED1E79",
    padding: "0px 12px",
    cursor: "pointer",
  },
  hr: {
    width: "90%",
    height: "4px",
    color: "#777",
    marginTop: ".4rem",
  },
  logout: {
    transition: "all ease-in-out .2s",
    background: "#f5cece",
    borderRadius: "8px",
    paddingBottom: "8px",
    marginTop: "4px",
    cursor: "pointer",
    "&:hover": {
      background: "#fabfbf",
    },
  },
}));

const HeaderIn = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { isAuthenticated, profile, logout } = props;
  const [dropdown, setDropdown] = useState(false);
  const [signingOut, setSigningOut] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();

  const toggleDropdown = () => {
    setDropdown((state) => !state);
  };

  const signOut = async () => {
    setSigningOut(true);
    await logout();
    setSigningOut(false);
  };

  return (
    <div className={classes.container}>
      <ClickAwayListener
        onClickAway={(e) => {
          if (
            dropdown &&
            !(e.target.id === "dropdown" || e.target.id === "profile-dropdown")
          ) {
            toggleDropdown();
          }
        }}
      >
        <Popper
          placement="bottom-end"
          id="drowpdown"
          open={dropdown}
          anchorEl={dropdownRef?.current}
          disablePortal={false}
          style={{
            zIndex: 1500,
            position: "fixed",
          }}
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
          ]}
          transition
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              timeout={350}
              style={{ transformOrigin: "right top" }}
            >
              <div
                className="flex flex-col items-center"
                style={{
                  padding: "8px",
                  width: "300px",
                  height: "120px",
                  background: "white",
                  boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "16px",
                  position: "absolute",
                  right: "-10px",
                  top: "30px",
                }}
              >
                <Tooltip title="view profile">
                  <div
                    className={`flex w-full py-2 px-2 items-center cursor-pointer hover:bg-slate-50 rounded-lg transition-all ease-in-out`}
                    onClick={() => {
                      toggleDropdown();
                      history.push("/app/profile");
                    }}
                  >
                    <Avatar
                      alt={profile?.name}
                      src={profile?.profile_picture}
                      sx={{ width: 35, height: 35 }}
                      style={{
                        background: "orange",
                        textTransform: "capitalize",
                      }}
                    >
                      {profile?.name?.charAt(0)?.toUpperCase()}
                    </Avatar>
                    <div className="flex flex-col pl-2">
                      <div className="flex flex-wrap text-16 font-semibold text-slate-600 capitalize">
                        {profile?.name}
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <hr className={classes.hr} />
                <div
                  className={`flex w-full py-2 px-2 justify-center ${classes.logout}`}
                >
                  <div
                    className={classes.secondaryText}
                    onClick={() => !signingOut && signOut()}
                  >
                    {signingOut ? (
                      <CircularProgress size={20} color="error" />
                    ) : (
                      "Logout"
                    )}
                  </div>
                </div>
              </div>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
      <div
        className={`cursor-pointer ${classes.icon}`}
        onClick={() => history.push("/")}
      >
        <img src="/images/logo.png" className={`${classes.logo}`} />
        <span data-aos="slide-right" className="md:block hidden">
          DELVEX
        </span>
      </div>
      <div className={classes.menus}>
        <Tooltip title="Leaderboard">
          <div
            className={`${classes.menu} ${
              location.pathname.startsWith("/leaderboard") ? classes.active : ""
            }`}
            onClick={() => history.push("/leaderboard")}
          >
            Leaderboard
          </div>
        </Tooltip>
        <Tooltip title="Exams">
          <div
            className={`${classes.menu} ${
              location.pathname.startsWith("/app/exam") ? classes.active : ""
            }`}
            onClick={() => history.push("/app/exams")}
          >
            Exams
          </div>
        </Tooltip>
      </div>
      <div className={classes.actions}>
        {/* <Settings size={20} className="mx-4 cursor-pointer" /> */}
        {/* <Bell size={20} className="mx-1 cursor-pointer" /> */}
        <Avatar
          ref={dropdownRef}
          onClick={() => toggleDropdown()}
          id="profile-dropdown"
          variant="circular"
          style={{
            width: "2.7rem",
            height: "2.7rem",
            background: "#0CCBFF",
            fontSize: "1.3rem",
            paddingTop: "2px",
            fontFamily: "Poppins",
          }}
          className="cursor-pointer font-semibold"
        >
          {profile?.name?.charAt(0)?.toUpperCase()}
        </Avatar>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.auth?.profile,
    isAuthenticated: state.auth?.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderIn);
