import React, { useState, Fragment, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import {
  BookOpen,
  Star,
  Users,
  ChevronRight,
  Facebook,
  Youtube,
  Instagram,
  GitHub
} from "react-feather";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import StarIcon from "@mui/icons-material/Star";

import mock_data from "./mock";
import Header from "../components/header";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100vw",
    height: "100vh",
    background: "#f1f1fd",
    overflow: "auto",
    overflowX: "hidden",
  },
  hero_section: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    minHeight: "90vh",
    [theme.breakpoints.down("md")]: {
      paddingTop: "4.5rem",
    },
  },
  hero_box1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 0 0 8%",
    width: "55%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  hero_box2: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "3%",
    marginTop: "2rem",
    width: "45%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  hero_heading: {
    fontSize: "50px",
    lineHeight: "1.2",
    color: "#522785",
    fontWeight: "700",
    fontFamily: "Poppins",
    margin: "6px 0 15px",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },
  },
  hero_subtext: {
    fontSize: "18px",
    marginTop: "0",
    lineHeight: "1.5",
    fontFamily: "Poppins",
    fontWeight: "500",
    color: "#888",
  },
  hero_img_box: {
    textAlign: "right",
    padding: "35px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9,
  },
  before: {
    position: "absolute",
    width: "125px",
    height: "170px",
    background: "#a08abd",
    left: 0,
    zIndex: -1,
    top: 0,
    bottom: "0",
    left: "calc(100% - 125px)",
    top: "calc(100% - 170px)",
    borderRadius: "0 8px 80px 8px",
  },
  after: {
    position: "absolute",
    width: "125px",
    height: "170px",
    background: "#a08abd",
    left: 0,
    zIndex: -1,
    top: 0,
    borderRadius: "80px 8px 0px 8px",
  },
  hero_img: {
    width: "100%",
    height: "100%",
    borderRadius: "16px 16px 16px 224px",
  },
  mid_box: {
    display: "flex",
    position: "absolute",
    width: "60vw",
    alignSelf: "center",
    background: "white",
    borderRadius: "12px",
    zIndex: 1100,
    marginTop: "1rem",
    height: "6rem",
    justifyContent: "space-between",
    padding: "0 8px",
    boxShadow: "1px 8px 12px 1px #bbb",
    [theme.breakpoints.down("md")]: {
      width: "90vw",
      overflowX: "auto",
    },
  },
  mid_icon: {
    color: "#522785",
    marginRight: "8px",
  },
  explore_section: {
    paddingTop: "6rem",
    paddingBottom: "2rem",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    width: "100%",
    background: "#DAF4CC",
  },
  explore_heading: {
    fontSize: "1.5rem",
    fontWeight: "600",
    color: "#522782",
  },
  explore_sub: {
    fontSize: ".9rem",
    color: "#555",
    maxWidth: "40%",
    marginBottom: "1.2rem",
    marginTop: ".8rem",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      maxWidth: "80%",
    },
  },
  grid: {
    display: "flex",
  },
  course_card: {
    background: "white",
    width: "17rem",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    margin: "1rem",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "all ease-in-out .1s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  card_heading: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#522782",
  },
  card_sub: {
    color: "#888",
    fontSize: ".8rem",
  },
  explore_card_img: {
    width: "2.5rem",
    height: "2.5rem",
    borderRadius: "50%",
    padding: "4px",
    marginRight: "8px",
  },
  carouselContainer: {
    display: "flex",
    flex: 1,
    height: "100%",
    width: "100%",
    margin: "2rem",
    padding: "4rem",
  },
  containerFooter: {
    display: "flex",
    justifyContent: "center",
    aligntems: "center",
    height: "100vh",
  },
  icon: {
    display: "flex",
    width: "10%",
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1.5rem",
    fontWeight: "500",
    fontFamily: "Bebas Neue",
    letterSpacing: 2,
    [theme.breakpoints.down("md")]: {},
  },
  hidescrollbar: {
    "&::scrollbar": {
      width: "0px",
      display: "none",
      background: "transparent",
    },
    "&::-webkit-scrollbar": {
      width: "0px",
      display: "none",
      background: "transparent",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
  },
}));


const measurement_id = 
    process.env.REACT_APP_ENV === "prod"
    ? "G-SB2GCN6NLX"
    : process.env.REACT_APP_ENV === "dev"
    ? "G-8R9WSBEM91"
    : "N/A"

ReactGA.initialize(measurement_id);

const HeroSection = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { isAuthenticated } = props;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <div className={classes.hero_section}>
      <div className={classes.hero_box1}>
        <div className={classes.hero_heading} data-aos="fade-up">
          Grow your skills to advance your career path
        </div>
        <div className={classes.hero_subtext} data-aos="fade-up">
          Ad fugiat aliquip deserunt quis ad labore esse consequat. Voluptate do
          nisi ad excepteur sunt dolor dolore exercitation exercitation mollit
          elit anim aliqua dolor.
        </div>
        <div className="flex w-full mt-6 mb-16 md:mb-2">
          <div
            className="text-16 text-white bg-primary rounded-md px-4 py-2 hover:bg-co_prime ease-in-out cursor-pointer transition-all"
            data-aos="zoom-in"
            onClick={() =>
              history.push(isAuthenticated ? "/app/exams" : "/register")
            }
          >
            Get Started
          </div>
          <div
            className="text-16 text-slate-700 bg-slate-50 rounded-md px-4 py-2 hover:bg-slate-100 ease-in-out cursor-pointer transition-all border-1 border-slate-400 ml-6"
            data-aos="zoom-in"
          >
            Learn more
          </div>
        </div>
      </div>
      <div className={classes.hero_box2}>
        <div className={classes.hero_img_box}>
          <span className={classes.before} />
          <img
            className={classes.hero_img}
            src="/images/hero_img.jpg"
            alt=""
            style={{
              width: isSafari ? "30vw" : "28vw",
            }}
          />
          <span className={classes.after} />
        </div>
      </div>
    </div>
  );
};

const ExploreCourseSection = ({ courses }) => {
  const classes = useStyles();
  const history = useHistory();

  const [hovering, setHovering] = useState(null);

  const CourseCard = ({ course, index }) => {
    return (
      <div
        className={classes.course_card}
        onMouseEnter={() => setHovering(index)}
        onMouseLeave={() => setHovering(null)}
        onClick={() => history.push("/courses/" + course.category)}
      >
        <img
          src={course.icon}
          alt={course.category}
          className={classes.explore_card_img}
        />
        <div className="flex flex-col flex-1">
          <div className={classes.card_heading}>{course.category}</div>
          <div className={classes.card_sub}>{`${course.count} ${
            course.count > 1 ? "Courses" : "Course"
          }`}</div>
        </div>
        {index !== null && hovering == index && (
          <div className="flex w-6 items-center justify-center">
            <ChevronRight size={32} color="#aaa" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classes.explore_section}>
      <div className={classes.explore_heading}>Explore Courses</div>
      <div className={classes.explore_sub}>
        Adipisicing enim ullamco laborum reprehenderit in fugiat cillum.
        Adipisicing enim ullamco laborum reprehenderit in fugiat cillum.
      </div>
      <div className={`grid grid-cols-3 gap-2`}>
        {courses.map((course, index) => {
          return <CourseCard course={course} index={index} key={index} />;
        })}
      </div>
      <div className="flex w-full justify-center items-center py-8">
        <button className="text-14 text-white bg-primary rounded-md px-4 py-2 hover:bg-co_prime ease-in-out cursor-pointer transition-all">
          Explore All Courses
        </button>
      </div>
    </div>
  );
};

const Footer = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { smScreen } = props;
  const faceBookHandleClick = () => {
    window.open('https://www.facebook.com/delvexcommunity/', '_blank');
  };
  const instagramHandleClick = () => {
    window.open('https://www.instagram.com/delvex.io', '_blank');
  };
  const youtubeHandleClick = () => {
    window.open('https://www.youtube.com/channel/UCBLokeZQq4tiBZu9GgeiNdQ', '_blank');
  };
  const githubHandleClick = () => {
    window.open('https://github.com/delvex-community', '_blank');
  };
  const Li = ({ title }) => { 
    return (
      <div
        className="text-slate-500 mb-1 cursor-pointer transition-all ease-in-out hover:text-slate-700 hover:font-bold"
        style={{
          fontSize: smScreen ? ".75rem" : ".9rem",
        }}
      >
        {title || ""}
      </div>
    );
  };

  return (
    <Fragment>
      <div className="w-full bg-slate-100 grid grid-cols-3 md:grid-cols-4 py-6 md:py-12 md:px-4">
        <div className="flex-col md:pl-16 hidden md:flex">
          <div className="text-14 font-bold text-slate-700 mb-2">Courses</div>
          <Li title={"AWS"} />
          <Li title="Docker" />
          <Li title="Kubernetes" />
          <Li title="GCP" />
        </div>
        <div className="flex flex-col pl-4 md:pl-16">
          <div className="text-12 md:text-14 font-bold text-slate-700 mb-2">
            Quick links
          </div>
          <Li title="AWS" />
          <Li title="Docker" />
          <Li title="Kubernetes" />
          <Li title="GCP" />
        </div>
        <div className="flex flex-col pl-4 md:pl-16">
          <div className="text-12 md:text-14 font-bold text-slate-700 mb-2">
            Contact us
          </div>
          <Li title="AWS" />
          <Li title="Docker" />
          <Li title="Kubernetes" />
          <Li title="GCP" />
        </div>
        <div className="flex flex-col pl-4 md:pl-16">
          <div className="text-12 md:text-14 font-bold text-slate-700 mb-2">
            More
          </div>
          <Li title="AWS" />
          <Li title="Docker" />
          <Li title="Kubernetes" />
          <Li title="GCP" />
        </div>
      </div>
      <div className="flex w-full bg-primary text-white py-3 items-center justify-center">
        <div onClick={() => history.push("/")} className={`${classes.icon} cursor-pointer`}
            
       >

        <span  className="ml-4 hidden md:block">DelveX</span>
      </div >
        <h4 
          style={{
            fontSize: smScreen ? "8px" : "12px",
            fontWeight: "bold",
            width: "40%"
          }}
        >
          © 2023 Delvex.  All rights reserved.
        </h4>
        <div className="flex w-1/5 justify-end md:pr-4">
          <GitHub
              onClick={githubHandleClick}
              size={smScreen ? 16 : 32}
              className="mr-2 md:bg-white md:bg-opacity-20 rounded-full md:p-1.5 md:hover:bg-opacity-30 cursor-pointer"
          />
          <Facebook
            onClick={faceBookHandleClick}
            size={smScreen ? 16 : 32}
            className="mr-2 md:bg-white md:bg-opacity-20 rounded-full md:p-1.5 md:hover:bg-opacity-30 cursor-pointer"
          />
          <Youtube
            onClick={youtubeHandleClick}
            size={smScreen ? 16 : 32}
            className="mr-2 md:bg-white md:bg-opacity-20 rounded-full md:p-1.5 md:hover:bg-opacity-30 cursor-pointer"
          />
          <Instagram
            onClick={instagramHandleClick}
            size={smScreen ? 16 : 32}
            className="mr-2 md:bg-white md:bg-opacity-20 rounded-full md:p-1.5 md:hover:bg-opacity-30 cursor-pointer"
          />
                    
        </div>
      </div>
    </Fragment>
  );
};

const ReviewBlock = ({ data }) => {
  const classes = useStyles();
  const { id, name, profile_picture, company, review, text, designation } =
    data;
  return (
    <div
      className={`flex w-full flex-col items-center relative mr-12 bg-white p-6 rounded-xl shadow-md pt-10 hover:scale-105 transition-all ease-in-out`}
      style={{
        height: "22rem",
        minWidth: "20rem",
        maxWidth: "20rem",
        borderTop: "4px solid #626EE3",
        marginBottom: "2rem", // Added margin to the bottom
      }}
    >
      <img
        src={profile_picture}
        style={{
          borderRadius: "50%",
          position: "absolute",
          top: -32,
          left: "38%",
          zIndex: 1500,
          height: "5rem",
          width: "5rem",
        }}
      />
      <div
        className="flex w-full justify-center text-slate-600 font-semibold text-16 capitalize"
        style={{
          color: "#626EE3",
          paddingTop: "1rem"
        }}
      >
        {name}
      </div>
      <div className="flex w-full justify-center text-slate-500 font-semibold text-14">
        {designation}
      </div>
      <div className="flex w-full justify-center my-2">
        {Array.from(Array(review).keys()).map((x) => (
          <StarIcon
            color="#626EE3" // Changed the color of the stars
            style={{ color: "#626EE3", fontSize: "18px", marginRight: "0.5rem" }} // Added some spacing between the stars and the company name
          />
        ))}
      </div>
      <div className="flex w-full justify-center text-slate-600 font-semibold text-16">
        {company}
      </div>
      <div
        className={`flex w-full justify-center text-slate-500  text-12 overflow-x-auto pt-4 px-2 ${classes.hidescrollbar} text-center`} // Added padding to the sides of the review text
        style={{
          fontSize: "1.0rem", // Increased the font size of the review text
        }}
      >
        {text}
      </div>
    </div>
  );
};

const CustomerReview = (props) => {
  const classes = useStyles();
  return (
    <div
      className={`flex w-full flex-col items-center md:px-20 py-4 mt-28 ${classes.review_container}`}
    >
      <div
        className="inline w-full justify-center text-slate-600 px-4"
        style={{
          fontFamily: "Raleway",
          fontSize: "2.5rem",
          textAlign: "center",
        }}
      >
        And over{" "}
        <span className="text-violet-600 px-2 mx-0 font-semibold">
          thousands
        </span>{" "}
        of satisfied learners
      </div>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        arrows={false}
        responsive={{
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3, // optional, default to 1.
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
          },
        }}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="all ease-in-out 1s"
        containerClass={classes.carouselContainer}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {mock_data.reviews.map((review) => (
          <ReviewBlock data={review} key={review.id} />
        ))}
      </Carousel>
    </div>
  );
};

const HomePage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { isAuthenticated } = props;

  const [scrollY, setScrollY] = useState(0);
  const [showHeader, setShowHeader] = useState(true);

  const handleNavigation = useCallback(
    (e) => {
      const y = e.target.scrollTop;
      setShowHeader(scrollY > y);
      setScrollY(y);
    },
    [scrollY]
  );

  const mid_box_content = [
    {
      icon: <BookOpen size={35} color="#522785" className="mr-2" />,
      heading: "12+",
      sub: "Courses",
    },
    {
      icon: (
        <QuizOutlinedIcon
          className={classes.mid_icon}
          style={{ fontSize: "2.2rem" }}
        />
      ),
      heading: "14+",
      sub: "Exams",
    },
    {
      icon: (
        <ComputerOutlinedIcon
          className={classes.mid_icon}
          style={{ fontSize: "2.2rem" }}
        />
      ),
      heading: "8+",
      sub: "Hands-on labs",
    },
    {
      icon: <Users size={32} color="#522785" className="mr-2" />,
      heading: "1000+",
      sub: "Students",
    },
    {
      icon: <Star size={32} color="#522785" className="mr-2" />,
      heading: "4.2",
      sub: "Rating",
    },
  ];

  const IconCard = ({ icon, heading, sub, border_right }) => {
    return (
      <div
        className="flex px-2 items-center justify-center"
        // data-aos="slide-left"
        data-aos-once={true}
      >
        <div className="flex justify-center items-center">{icon}</div>
        <div
          className={`flex flex-col ml-2 ${
            border_right ? "border-r-1 border-slate-200" : ""
          } pr-4`}
        >
          <div className="text-14 font-bold m-0 p-0">{heading}</div>
          <div className="flex text-12 m-0 p-0">{sub}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container} onScroll={handleNavigation}>
      <Header show={showHeader} isAuthenticated={isAuthenticated} />
      <HeroSection isAuthenticated={isAuthenticated} />
      <div className="flex relative w-full justify-center z-0">
        <div className={classes.mid_box}>
          {mid_box_content.map((item, index) => (
            <IconCard
              key={index}
              border_right={index !== mid_box_content?.length - 1}
              icon={item.icon}
              heading={item.heading}
              sub={item.sub}
            />
          ))}
        </div>
      </div>
      <CustomerReview />
      <ExploreCourseSection courses={mock_data.courses} />
      <Footer smScreen={smScreen} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth?.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
