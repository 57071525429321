import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";

import MiniCard from "../components/miniCard";
import { getLevels } from "../store/actions/exam.action";
import { toggleSnakebar } from "../store/actions";

const useStyles = makeStyles((theme) => ({
  textBold: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "31px",
    textAlign: "center",
    color: "#2D3958",
    margin: "16px 0px",
  },
  text1: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
  },
  subText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "21px",
    textAlign: "center",
    color: "#2D3958",
    margin: "8px 0px",
  },
  blockText: {
    fontFamily: "Poppins" ,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "31px",
  },
}));

const LevelListPage = (props) => {
  const classes = useStyles();
  const [levels, setLevels] = useState(null);
  const [fetching, setFetching] = useState(false);

  const { toggleSnakebar } = props;

  const { getLevels } = props;

  const callApi = async () => {
    setFetching(true);
    const list = await getLevels(props.match.params.id);
    setLevels(list || []);
    setFetching(false);
  };

  useEffect(() => {
    callApi();
  }, []);

  return (
    <div className="flex flex-col w-full h-full p-6">
      {fetching ? (
        <div className="flex w-full h-full justify-center items-center">
          <CircularProgress />
        </div>
      ) : !levels?.length ? (
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-col w-full min-h-full justify-center items-center">
            {/* <img src="icons/lockGraphic.svg" alt="lock icon" /> */}
            <div className={classes.textBold}>No levels found</div>
          </div>
        </div>
      ) : (
        <div className="flex w-full flex-col h-full p-4">
          <div className="flex w-full justify-between items-center px-5">
            <div className={`${classes.blockText} text-slate-600`}>Levels</div>
          </div>
          <div
            className="flex w-full h-full py-4 overflow-hidden"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignContent: "flex-start" /* NEW */,
              rowGap: "12px",
              fontFamily: "Poppins"
            }}
          >
            {levels.map((level, index) => (
              <MiniCard
                id={level._id}
                title={level.name}
                key={level._id}
                questions={level.questions}
                attempted={level.attempted}
                unlock={level.unlock}
                index={index}
                toggleSnakebar={toggleSnakebar}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevels: (id) => dispatch(getLevels(id)),
    toggleSnakebar: (show, msg, severity) =>
      dispatch(toggleSnakebar(show, msg, severity)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LevelListPage);
