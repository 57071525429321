import React, { useState, useEffect, Fragment } from "react";
import { CircularProgress, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Lottie from "react-lottie";
import {
  CheckSquare,
  Award,
  MinusSquare,
  PlusSquare,
  Clock,
} from "react-feather";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import * as moment from "moment";

import examLottieData from "../utilities/lotties/exam-lottie.json";
import LeaderboardDrawer from "../components/leaderboardDrawer";
import { getLevel, getLeaderboardData } from "../store/actions/exam.action";
import { loadTest } from "../store/actions/livetest.actions";
import { toggleSnakebar } from "../store/actions/snakebarActions";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    transition: "all ease-in-out .2s",
    background: "#fff",
    boxShadow: "2px 4px 8px 1px #dadada",
    height: "20rem",
    width: "18rem",
    borderRadius: "1.4rem",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "8px 4px 8px 1px #dadada",
    },
  },
  title: {
    fontSize: "1.4rem",
    paddingLeft: ".8rem",
  },
  btn: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const ExamDetailPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { getLevel, loadTest, live, getLeaderboardData, toggleSnakebar, uid } =
    props;
  const [test, setTest] = useState(null);

  const [loader, setLoader] = useState(true);
  const [examId, setExamId] = useState(null);
  const [loadingTest, setLoadingTest] = useState(false);
  const [viewLeaderboard, setViewLeaderboard] = useState(false);
  const [hovering, setHovering] = useState(false);

  const callApi = async () => {
    setLoader(true);
    const id = props?.match?.params?.id;
    setExamId(id);
    if (id) {
      // get test details
      const details = await getLevel(id);
      setTest(details);
    }
    setLoader(false);
  };

  useEffect(() => {
    callApi();
  }, [props?.match?.params?.id]);

  const attemptTestClick = async () => {
    setLoadingTest(true);
    const { loaded, error } = await loadTest(examId);
    if (loaded) {
      history.push(`/app/exam/${examId}`);
    } else {
      // show error snakebar
      toggleSnakebar(
        true,
        error || "Something went wrong! Try again after some time",
        "error"
      );
    }
    setLoadingTest(false);
  };

  const toggleLeaderboard = () => {
    setViewLeaderboard((state) => !state);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: examLottieData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex flex-col w-full h-full overflow-auto px-2 md:px-4">
      <LeaderboardDrawer
        open={viewLeaderboard}
        toggleLeaderboard={toggleLeaderboard}
        test_id={examId}
        getLeaderboardData={getLeaderboardData}
        userId={uid}
      />
      {loader ? (
        <div
          className="flex min-w-full justify-center items-center"
          style={{
            height: "70vh",
          }}
        >
          <CircularProgress size={32} />
        </div>
      ) : (
        <div className="flex w-full items-center mb-1 md:mb-4 justify-center">
          <div
            className="flex self-center px-8 py-8 bg-white rounded-lg shadow-slate-200 shadow-md my-1 md:my-8"
            style={{
              width: smScreen ? "100%" : "80%",
            }}
          >
            <div className="flex flex-col w-full md:w-3/4 h-full">
              <div
                className="text-slate-400 font-bold my-1 uppercase"
                style={{
                  fontSize: ".9rem",
                }}
              >
                {test?.test?.name}
              </div>
              <div
                className="text-slate-600 font-semibold mb-4"
                style={{
                  fontSize: "1.4rem",
                }}
              >
                {test?.name}
              </div>
              <div
                className="text-slate-500 text-justify pr-6"
                style={{
                  fontSize: ".9rem",
                }}
              >
                {test?.test?.details}
              </div>
              <div className="flex w-full justify-start items-center my-8 flex-wrap">
                <div className="flex items-center p-4 bg-slate-50 mr-4 mb-4 sm:w-auto w-full">
                  <PlusSquare size={18} color="#444" />
                  <div
                    className="rounded-lg bg-slate-100 pl-2 font-semibold flex items-center text-slate-700"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    Added on {moment(test?.createdAt).format("MMM DD, YYYY")}
                  </div>
                </div>
                <div className="flex items-center p-4 bg-slate-50 mr-4 mb-4 sm:w-auto w-full">
                  <CheckSquare size={18} color="#444" />
                  <div
                    className="rounded-lg bg-slate-100 pl-2 font-semibold flex items-center text-slate-700"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    Total Questions: {test.questions}
                  </div>
                </div>
                <div className="flex items-center p-4 bg-slate-50 mr-4 mb-4 sm:w-auto w-full">
                  <MinusSquare size={18} color="#444" />
                  <div
                    className="rounded-lg bg-slate-100 pl-2 font-semibold flex items-center text-slate-700"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    Min. Passing Criteria:{" "}
                    {!test.questions
                      ? "N/A"
                      : `${Math.ceil(
                          (test.min_criteria / test.questions) * 100
                        )} %`}
                  </div>
                </div>
                <div className="flex items-center p-4 bg-slate-50 mr-4 mb-4 sm:w-auto w-full">
                  <Award size={18} color="#444" />
                  <div
                    className="rounded-lg bg-slate-100 pl-2 font-semibold flex items-center text-slate-700"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    {test.score} points
                  </div>
                </div>
                <div className="flex items-center p-4 bg-slate-50 mr-4 mb-4 sm:w-auto w-full">
                  <Clock size={18} color="#444" />
                  <div
                    className="rounded-lg bg-slate-100 pl-2 font-semibold flex items-center text-slate-700"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    {test.duration || 30} minutes
                  </div>
                </div>
              </div>
              <div className="flex md:flex-row flex-col w-full justify-start items-center mb-8 gap-2">
                <Button
                  style={{
                    backgroundColor:
                      loadingTest || !test.questions ? "#F1F1F1" : "#14C38E",
                    color: loadingTest || !test.questions ? "gray" : "white",
                    boxShadow: "none",
                    padding: "8px 18px",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    marginRight: ".7rem",
                    fontWeight: 700,
                  }}
                  className={classes.btn}
                  onClick={() => attemptTestClick()}
                  variant="contained"
                  disabled={loadingTest || !test.questions}
                >
                  {loadingTest ? (
                    <Fragment>
                      <CircularProgress
                        size={18}
                        style={{
                          color: "#444",
                          marginRight: ".8rem",
                        }}
                      />
                      <span>Loading test ...</span>
                    </Fragment>
                  ) : test?.attempted_before ? (
                    "Reattempt test"
                  ) : (
                    "Attempt test"
                  )}
                </Button>
                <Button
                  style={{
                    backgroundColor:
                      loadingTest || !test.next_level
                        ? "#f1f1f1"
                        : test.next_level
                        ? hovering
                          ? "#f56342"
                          : "#DF7861"
                        : "#eaeaea",
                    color: loadingTest || !test.next_level ? "gray" : "white",
                    boxShadow: "none",
                    padding: "8px 18px",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    marginRight: ".7rem",
                    fontWeight: 700,
                  }}
                  className={`${classes.btn} ${classes.next_level_btn} transition-all ease-in-out`}
                  onClick={() => history.push(`/app/exams/${test.next_level}`)}
                  variant="contained"
                  disabled={loadingTest || !test.next_level}
                  onMouseEnter={() =>
                    !loadingTest && test.next_level && setHovering(true)
                  }
                  onMouseLeave={() =>
                    !loadingTest && test.next_level && setHovering(false)
                  }
                >
                  Next Level
                </Button>
                <Button
                  className={classes.btn}
                  variant="contained"
                  style={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: "8px",
                    border: "1px solid #cacaca",
                    color: "rgb(100 116 139)",
                    padding: "8px 18px",
                    textTransform: "capitalize",
                    marginRight: ".7rem",
                    fontWeight: 700,
                  }}
                  onClick={() => toggleLeaderboard()}
                >
                  View Leaderboard
                </Button>
              </div>
            </div>
            <div className="flex flex-col w-1/4 min-h-full items-center justify-center px-4 md:block hidden">
              <Lottie
                options={defaultOptions}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    live: state.liveTest?.live,
    uid: state.auth.profile?._id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel: (id) => dispatch(getLevel(id)),
    loadTest: (id) => dispatch(loadTest(id)),
    getLeaderboardData: (test_id) => getLeaderboardData(test_id),
    toggleSnakebar: (show, msg, severity) =>
      dispatch(toggleSnakebar(show, msg, severity)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamDetailPage);
