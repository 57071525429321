import React, { useState, useEffect, Fragment } from "react";
import { CircularProgress, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import * as moment from "moment";

import { toggleSnakebar } from "../store/actions";
import axios from "../utilities/axios";
import useInterval from "../customHooks/useInterval";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "90vh",
    overflow: "hidden",
  },
  text1: {
    fontSize: "1.3rem",
  },
  text2: {
    fontSize: "1rem",
    margin: ".4rem 0",
  },
  text_blue: {
    fontSize: "1rem",
    color: "dodgerblue",
    padding: "0 .2rem",
  },
  digit: {
    border: "1px solid #ccc",
    borderRadius: "6px",
    fontSize: "1.4rem",
    padding: "4px",
    margin: "8px",
    width: "3rem",
    height: "3rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
}));

const VerifyOTPPage = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const [timer, setTimer] = useState(300);
  const [resending, setResending] = useState(false);
  const [digits, setDigits] = useState([-1, -1, -1, -1, -1]);

  const { toggleSnakebar } = props;

  const email = props.history.location.state?.email;

  useInterval(() => {
    if (timer > 0) setTimer(timer - 1);
  }, 1000);

  const resetTimer = () => {
    setTimer(300);
  };

  useEffect(() => {
    // email && requestOTP();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // if (
    //   e?.nativeEvent?.inputType === "deleteContentBackward" &&
    //   Number(name) >= 1
    // )
    //   document.getElementById(`id-${+name - 1}`).focus();
    if (isNaN(value)) return;
    const values = [...digits];
    values[name] = value;
    if (name < 4 && value) document.getElementById(`id-${+name + 1}`).focus();
    setDigits(values);
  };

  const handlePaste = (event) => {
    const pastedData = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    if (!pastedData || isNaN(pastedData)) return;
    const values = [];
    for (let index = 0; index < 5; index++) {
      values[index] = pastedData[index] || -1;
    }

    setDigits(values);
  };

  const verifyOTP = async (email, otp) => {
    const token = localStorage.getItem("token");
    const resp = await axios.post(
      "/auth/verify-otp",
      { otp, email },
      { headers: { authorization: `Bearer ${token}` } }
    );
    return resp.data;
  };

  const verify = async () => {
    try {
      setLoader(true);
      const otp = digits.join("");
      const verified = await verifyOTP(email, otp);
      setLoader(false);
      if (verified?.verified) {
        toggleSnakebar(true, "Email Verified.", "success");
        return history.push({
          pathname: "/login",
          state: { registered: true },
        });
      } else if (verified?.message) {
        toggleSnakebar(true, verified?.message, "error");
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const requestOTP = async () => {
    try {
      setResending(true);
      const token = localStorage.getItem("token");
      const resp = await axios.post(
        "/auth/send-otp",
        { email },
        { headers: { authorization: `Bearer ${token}` } }
      );
      resetTimer();

      setResending(false);
      toggleSnakebar(true, "OTP Sent to your email.", "success");
    } catch (error) {
      setResending(false);
    }
  };

  if (!email) {
    history.push("/");
    return <div></div>;
  }

  return (
    <div
      className={`flex flex-col justify-center items-center w-full h-full overflow-auto px-4 bg-white ${classes.container}`}
      onPaste={handlePaste}
    >
      <div
        className="flex items-start fixed top-6 left-6 cursor-pointer"
        style={{
          paddingBottom: "4%",
        }}
        onClick={() => history.push("/")}
      >
        <img
          src="/images/logo.png"
          width="30"
          height="30"
          style={{
            marginBottom: "3rem",
          }}
        />
        <span
          className="font-semibold text-slate-700 pl-2"
          style={{
            background: "rgb(79,126,247)",
            background:
              "linear-gradient(45deg, rgba(79,126,247,1) 14%, rgba(10,127,251,1) 22%, rgba(111,181,251,1) 27%, rgba(10,127,251,1) 32%, rgba(111,181,251,1) 37%)",
            webkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: "1.2rem",
          }}
        >
          Delvex
        </span>
      </div>
      {timer === 0 ? (
        <Fragment>
          <h4 className={`text-slate-600 font-semibold ${classes.text1}`}>
            Email verification failed
          </h4>
          <Button
            variant="outlined"
            fullWidth
            size="small"
            style={{
              borderColor: "#cacaca",
              color: "#4a4a4a",
              padding: "8px 0",
              marginTop: "1.4rem",
              fontWeight: "600",
              borderRadius: ".8rem",
              width: "20rem",
              cursor: "pointer",
            }}
            // disabled={loader || digits?.some((digit) => digit === -1 || !!!digit)}
            onClick={() => requestOTP()}
          >
            {resending ? (
              <CircularProgress
                size={20}
                style={{
                  color: "black",
                }}
              />
            ) : (
              "Resent OTP"
            )}
          </Button>
          <Button
            variant="contained"
            fullWidth
            size="small"
            style={{
              borderColor: "#cacaca",
              color: "",
              padding: "8px 0",
              backgroundColor: "#554994",
              marginTop: "1.4rem",
              fontWeight: "600",
              borderRadius: ".8rem",
              width: "20rem",
              cursor: "pointer",
            }}
            // disabled={loader || digits?.some((digit) => digit === -1 || !!!digit)}
            onClick={() => history.push("/")}
          >
            Return to homepage
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <h4 className={`text-slate-600 font-semibold ${classes.text1}`}>
            OTP is sent to your email address
          </h4>
          <h4
            className={`text-slate-400 font-semibold ${classes.text2}`}
            style={{
              marginBottom: "1rem",
            }}
          >
            Check your email address
          </h4>
          <h4
            className={`text-slate-400 font-semibold bg-slate-50 p-4 px-12 rounded-xl ${classes.text2}`}
          >
            Your OTP will expire in
            <span className="ml-2">
              {moment().startOf("day").second(timer).format("mm:ss")}
            </span>
          </h4>
          <div className={`flex w-full justify-center items-center my-8`}>
            {digits?.map((digit, index) => (
              <input
                id={`id-${index}`}
                key={index}
                name={index}
                autoFocus={index === 0}
                value={digit === -1 || !!!digit ? null : digit}
                class={classes.digit}
                type="text"
                onChange={handleChange}
                onkeyup={`tabChange(${index + 1})`}
                maxLength={1}
                placeholder={"-"}
              />
            ))}
          </div>
          <Button
            variant="contained"
            fullWidth
            size="small"
            style={{
              background:
                loader || digits?.some((digit) => digit === -1 || !!!digit)
                  ? "#cacaca"
                  : "dodgerblue",
              padding: "8px 0",
              fontWeight: "600",
              borderRadius: ".8rem",
              width: "20rem",
              color:
                loader || digits?.some((digit) => digit === -1 || !!!digit)
                  ? "#444"
                  : "white",
              cursor: "pointer",
            }}
            disabled={
              loader || digits?.some((digit) => digit === -1 || !!!digit)
            }
            onClick={() => verify()}
          >
            {loader ? (
              <CircularProgress
                size={20}
                style={{
                  color: "white",
                }}
              />
            ) : (
              "Verify"
            )}
          </Button>
          <Button
            variant="outlined"
            fullWidth
            size="small"
            style={{
              borderColor: "#cacaca",
              color: "#4a4a4a",
              padding: "8px 0",
              marginTop: "1.4rem",
              fontWeight: "600",
              borderRadius: ".8rem",
              width: "20rem",
              cursor: "pointer",
            }}
            // disabled={loader || digits?.some((digit) => digit === -1 || !!!digit)}
            onClick={() => requestOTP()}
          >
            {resending ? (
              <CircularProgress
                size={20}
                style={{
                  color: "black",
                }}
              />
            ) : (
              "Resent OTP"
            )}
          </Button>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSnakebar: (show, msg, severity) =>
      dispatch(toggleSnakebar(show, msg, severity)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOTPPage);
