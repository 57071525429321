import React from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  text1: {
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "17px",
    lineHeight: "34px",
    textAlign: "center",
    letterSpacing: "4px",
    textTransform: "uppercase",
    color: "#565872",
    marginBottom: "1rem",
  },
  btnText: {
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "white",
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className="flex flex-col w-screen h-screen overflow-none items-center justify-center">
      <div className="flex w-full h-1/2 justify-center">
        <img src="/icons/404.svg" alt="404 graphic" />
      </div>
      <div className="flex flex-col w-full h-1/2 justify-end items-center pb-40">
        <div className={classes.text1}>Oops! page not found</div>
        <div className="flex w-full justify-center">
          <div
            customStyles={{
              padding: "11px 16px",
              width: "139px",
              height: "40px",
            }}
            clickHandler={() => history.push("/")}
          >
            <span className={classes.btnText}>back to home</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
