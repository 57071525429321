import React, { useState, useEffect, Fragment } from "react";
import { Avatar, CircularProgress, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import * as moment from "moment";

import { getGlobalLeaderboard } from "../store/actions/exam.action.js";
import { useTheme } from "@emotion/react";
import Header from "../components/header.js";
import HeaderIn from "../components/headerIn.js";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    transition: "all ease-in-out .2s",
    background: "#fff",
    boxShadow: "2px 4px 8px 1px #dadada",
    height: "20rem",
    width: "18rem",
    borderRadius: "1.4rem",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "8px 4px 8px 1px #dadada",
    },
  },
  title: {
    fontSize: "1.4rem",
    paddingLeft: ".8rem",
  },
}));

const LeaderboardPage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { getGlobalLeaderboard, isAuthenticated } = props;

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState(null);

  const callApi = async () => {
    setLoader(true);
    const leaderboard = await getGlobalLeaderboard();
    setData(leaderboard);
    setLoader(false);
  };

  useEffect(() => {
    callApi();
  }, []);

  const headCells = [
    { label: "Rank", justify: "start", width: "8rem", paddingLeft: "1rem" },
    { label: "", justify: "center", width: "3rem", pl: 4, flex: true },
    { label: "Name", justify: "start", width: "12rem" },
    { label: "Score", justify: "start", width: "8rem" },
    { label: "Completed", justify: "center", width: "8rem" },
    { label: "Joined", justify: "end", width: "8rem", pr: 4 },
  ];

  return (
    <div
      className="flex flex-col h-full overflow-auto px-4 md:px-0 bg-white"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {isAuthenticated ? <HeaderIn /> : <Header />}
      {loader ? (
        <div
          className="flex min-w-full justify-center items-center"
          style={{
            height: smScreen ? "90vh" : "70vh",
          }}
        >
          <CircularProgress size={32} />
        </div>
      ) : !data?.length ? (
        <div className="flex w-full justify-center items-center h-screen">
          <h4
            className="text-slate-600 font-semibold"
            style={{
              fontSize: "1.2rem",
            }}
          >
            Leaderboard is not available currently
          </h4>
        </div>
      ) : (
        <div
          className="flex flex-col"
          style={{
            padding: smScreen ? "4px 10%" : "",
            overflow: "auto",
          }}
        >
          <div
            className="flex py-4 px-4 items-center bg-white self-center mt-14"
            style={{
              // width: smScreen ? "95%" : "78%",
              position: "sticky",
              top: 0,
              zIndex: 1300,
              paddingLeft: smScreen ? "27rem" : "",
            }}
          >
            {headCells?.map((cell, index) => (
              <div
                className={`flex items-center justify-${cell.justify}  pl-${
                  cell.pl
                } pr-${cell.pr} ${
                  cell?.flex ? "flex-1" : ""
                } text-slate-600 font-semibold`}
                key={index}
                style={{
                  fontFamily: "raleway",
                  minWidth: cell.width,
                  maxWidth: cell.width,
                  paddingLeft: cell.paddingLeft,
                }}
              >
                {cell.label}
              </div>
            ))}
          </div>
          <div
            className="flex flex-col self-center items-center flex-1"
            style={{
              background: "white",
              borderRadius: "8px",
              height: "100%",
              margin: "1rem 0 2rem 0",
              paddingLeft: smScreen ? "27rem" : "",
              // width: smScreen ? "95%" : "78%",
            }}
          >
            {data?.map((row, index) => (
              <div
                className="flex w-full py-3 md:py-4 px-1 md:px-4 items-center mb-2 border-b-1 border-slate-200 rounded-lg hover:scale-105 transition-all ease-in-out hover:shadow-lg"
                key={index}
                style={{
                  background:
                    row.rank === 1
                      ? "#8fe3cf"
                      : row.rank === 2
                      ? "#ffe7bf"
                      : row.rank === 3
                      ? "#ffffde"
                      : "#fff",
                }}
              >
                <div
                  className="flex items-center justify-start px-4"
                  style={{
                    minWidth: "8rem",
                  }}
                >
                  #{row.rank}
                </div>
                <div
                  className="flex items-center justify-center w-2/12 pl-4"
                  style={{
                    minWidth: "8rem",
                  }}
                >
                  <Avatar
                    alt={row?.name}
                    sx={{
                      width: smScreen ? 25 : 35,
                      height: smScreen ? 25 : 35,
                    }}
                    variant="rounded"
                    style={{
                      background: "orange",
                      textTransform: "capitalize",
                      border: "2px solid #fef9a7",
                      fontSize: smScreen ? "12px" : "1rem",
                    }}
                  >
                  {row.name
                    ? row.name.charAt(0)?.toUpperCase()
                    : 'N/A'
                  }
                  </Avatar>
                </div>
                <div
                  className={`flex items-center justify-start`}
                  style={{
                    minWidth: "8rem",
                  }}
                >
                  {row.name}
                </div>
                <div
                  className={`flex items-center justify-start`}
                  style={{
                    minWidth: "8rem",
                  }}
                >
                  {row.score}
                </div>
                <div
                  className={`flex items-center justify-center`}
                  style={{
                    minWidth: "8rem",
                  }}
                >
                  {row.completed}
                </div>
                <div
                  className={`flex items-center justify-center`}
                  style={{
                    minWidth: "8rem",
                  }}
                >
                  {moment(row.createdAt).format("MMM, DD YYYY")}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalLeaderboard: () => dispatch(getGlobalLeaderboard()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardPage);
