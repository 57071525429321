import React, { useState, useEffect, Fragment } from "react";
import { CircularProgress, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Lottie from "react-lottie";

import { getTestReport } from "../store/actions/livetest.actions";
import checkLottieData from "../utilities/lotties/exam-pass.json";
import { CheckSquare, MinusSquare, PlusSquare } from "react-feather";
import * as moment from "moment";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    transition: "all ease-in-out .2s",
    background: "#fff",
    boxShadow: "2px 4px 8px 1px #dadada",
    height: "20rem",
    width: "18rem",
    borderRadius: "1.4rem",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "8px 4px 8px 1px #dadada",
    },
  },
  title: {
    fontSize: "1.4rem",
    paddingLeft: ".8rem",
  },
}));

const TestReportPage = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { getTestReport } = props;

  const [report, setReport] = useState(null);
  const [loader, setLoader] = useState(true);

  const callApi = async () => {
    setLoader(true);
    const report_id = props?.match?.params?.report_id;
    if (report_id) {
      // get test details
      const details = await getTestReport(report_id);
      setReport(details);
    }
    setLoader(false);
  };

  useEffect(() => {
    callApi();
  }, []);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: checkLottieData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      className="flex flex-col w-full overflow-auto px-4"
      style={{
        minHeight: "100vh",
      }}
    >
      {loader ? (
        <div
          className="flex min-w-full justify-center items-center"
          style={{
            height: "70vh",
          }}
        >
          <CircularProgress size={32} />
        </div>
      ) : (
        <div className="flex flex-col h-4/6 w-full justify-center">
          <h4
            className="flex justify-center w-full py-4 text-slate-400 font-semibold uppercase"
            style={{
              fontSize: "1.4rem",
              fontFamily: "Poppins",
            }}
          >
            {report?.test_id?.name}
          </h4>
          {report?.pass && (
            <div
              className="flex justify-center"
              style={{
                fontFamily: "Roboto",
                fontSize: "1rem",
                color: "#777",
                fontWeight: 700,
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "2rem",
                  height: "2rem",
                  alignItems: "flex-start",
                }}
              >
                <Lottie
                  options={defaultOptions}
                  style={{
                    width: "6.5rem",
                    height: "6.5rem",
                    margin: "-24px",
                  }}
                />
              </div>
              Congratulations, you have successfully completed this level.
            </div>
          )}
          {!report?.pass && (
            <div
              className="flex justify-center"
              style={{
                fontFamily: "Poppins",
                fontSize: "1rem",
                color: "#777",
                fontWeight: 700,
              }}
            >
              You were not able to clear the minimum criteria to complete this
              level.
            </div>
          )}
          <div className="flex justify-center py-6">
            <Button
              style={{
                backgroundColor: "#839AA8",
                color: "white",
                boxShadow: "none",
                padding: "8px 18px",
                marginRight: ".6rem",
                borderRadius: "8px",
                textTransform: "capitalize",
                marginRight: ".7rem",
                fontWeight: 700,
              }}
              onClick={() => history.push(`/app/exams/${report?.level_id?._id}`)}
              variant="contained"
            >
              Go back to exam page
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTestReport: (id) => dispatch(getTestReport(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestReportPage);
