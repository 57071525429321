import React, { Fragment } from "react";

import { makeStyles } from "@mui/styles";
import { useHistory, useLocation } from "react-router-dom";
import { Tooltip, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { LogIn } from "react-feather";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    width: ({ isLeaderboard }) => (isLeaderboard ? "100vw" : "82vw"),
    background: "white",
    height: "4.5rem",
    padding: "1rem 2rem",
    borderRadius: ({ isLeaderboard }) => (isLeaderboard ? "1px" : "8px"),
    zIndex: 1500,
    alignItems: "center",
    alignSelf: "center",
    transform: ({ isLeaderboard }) => (isLeaderboard ? "" : "translateX(10%)"),
    transition: "transform ease-in-out .4s, top ease-in-out .4s",
    top: 20,
    left: 0,
    "&:hover": {
      boxShadow: "2px 2px 18px 1px #d9d9d9",
    },
    [theme.breakpoints.down("md")]: {
      padding: ".7rem",
      height: "3.5rem",
      width: "100vw !important",
      top: 0,
      left: 0,
      transform: "none !important",
      boxShadow: "-2px 2px 8px 1px #d9d9d9",
      "&:hover": {
        transform: "none",
      },
    },
  },
  icon: {
    display: "flex",
    width: "20%",
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "1.5rem",
    fontWeight: "500",
    fontFamily: "Bebas Neue",
    letterSpacing: 2,
    [theme.breakpoints.down("md")]: {},
  },
  menus: {
    display: "flex",
    width: "40%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  actions: {
    display: "flex",
    width: "25%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  btn: {
    fontSize: ".9rem",
    color: "#347fff",
    fontWeight: "500",
    fontFamily: "Raleway",
    padding: "8px 12px",
    cursor: "pointer",
    margin: "0 .5rem",
    color: "#522785",
    [theme.breakpoints.down("md")]: {
      paddingRight: "0",
    },
  },
  primary: {
    border: "1px solid #522785",
    borderRadius: "4px",
    background: "#eee9f5",
    transition: "all ease-in-out .1s",
  },
  menu: {
    color: "#555",
    margin: "0 12px",
    fontSize: ".9rem",
    textTransform: "capitalize",
    cursor: "pointer",
    transition: "all ease-in-out .1s",
    "&:hover": {
      fontWeight: "bold",
      color: "#347fff",
    },
  },
}));

const Header = ({ show, isAuthenticated }) => {
  const location = useLocation();
  const isLeaderboard = location.pathname?.startsWith("/leaderboard");

  const classes = useStyles({ isLeaderboard });
  const history = useHistory();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      className={classes.container}
      style={{
        top: show ? (smScreen ? 0 : 20) : -80,
        position: isLeaderboard ? "initial" : "fixed",
      }}
    >
      <div
        className={`${classes.icon} cursor-pointer`}
        onClick={() => history.push("/")}
      >
        <img
          className={classes.logo}
          src="/images/logo.png"
          alt="delvex"
          width={smScreen ? "25" : "40"}
          height={smScreen ? "25" : "40"}
        />
        <span className="ml-2 hidden md:block">DelveX</span>
      </div>
      <div className={classes.menus}>
        {isAuthenticated ? (
          <Fragment>
            <div className={classes.menu}>Courses</div>
            <div
              className={classes.menu}
              onClick={() => history.push("/leaderboard")}
            >
              Leaderboard
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div
              className={classes.menu}
              onClick={() => history.push("/pricing")}
            >
              Pricing
            </div>
            <div
              className={classes.menu}
              onClick={() => history.push("/about-us")}
            >
              About us
            </div>
          </Fragment>
        )}
        <div className={`${classes.menu} hidden`}>Pricing</div>
      </div>
      <div className={classes.actions}>
        {isAuthenticated ? (
          <div
            className={`${classes.btn} ${classes.primary} cursor-pointer`}
            style={{
              padding: ".4rem .6rem",
            }}
            onClick={() => history.push("/app/profile")}
          >
            App
          </div>
        ) : smScreen ? (
          <div className={classes.btn} onClick={() => history.push("/login")}>
            <Tooltip title="authenticate">
              <LogIn size={20} />
            </Tooltip>
          </div>
        ) : (
          <Fragment>
            <div className={classes.btn} onClick={() => history.push("/login")}>
              Login
            </div>
            <div
              className={`${classes.btn} ${classes.primary}`}
              onClick={() => history.push("/register")}
            >
              Register
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Header;
