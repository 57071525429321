import * as React from "react";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { connect } from "react-redux";
import { toggleSnakebar } from "../store/actions/snakebarActions";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarComponent = (props) => {
  const { show, severity, msg, toggle } = props;

  const getColors = (severity) => {
    switch (severity) {
      case "success":
        return {
          backgroundColor: "#EDF6EC",
          color: "#773E3E",
        };
      case "error":
        return {
          backgroundColor: "#FDECEC",
          color: "#773E3E",
        };
      case "info":
        return {
          backgroundColor: "#FFF4E5",
          color: "#773E3E",
        };
      default:
        return {
          backgroundColor: "#FFF4E5",
          color: "#773E3E",
        };
    }
  };

  return (
    <Snackbar
      open={show}
      autoHideDuration={6000}
      onClose={toggle}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      TransitionComponent={Slide}
      style={{
        zIndex: 1600,
      }}
    >
      <Alert
        onClose={toggle}
        severity={severity}
        sx={{ width: "100%", ...getColors(severity), fontSize: "12px" }}
        size="small"
        icon={false}
        style={{
          fontSize: "12px",
        }}
      >
        {msg}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = (state) => {
  return {
    show: state?.snakebar?.show,
    msg: state?.snakebar?.msg,
    severity: state?.snakebar?.severity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggle: () => dispatch(toggleSnakebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarComponent);
