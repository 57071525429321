import axios from "../../utilities/axios";

import { LOGGED_IN, LOGOUT, SET_PROFILE, UPDATE_PROFILE } from "./actionTypes";

export const login = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOGGED_IN,
        payload: {
          isAuthenticated: true,
        },
      });
    } catch (error) {
      return error;
    }
  };
};

export const register = (payload) => {
  return async (dispatch) => {
    try {
      const { email, name, password } = payload;
      const registered = await axios.post("/auth/register", {
        email,
        password,
        name,
      });
      return {
        success: true,
      };
    } catch (error) {
      const status = error?.response?.status;
      if (status === 400) {
        return {
          success: false,
          error: error?.response?.data?.message,
        };
      }
      return {
        success: false,
        error: "Something went wrong",
      };
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    localStorage.removeItem("token");

    await axios.get("/auth/signout", {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: LOGOUT,
    });
  };
};

export const setProfile = (token) => {
  return async (dispatch) => {
    try {
      if (!token) return;
      const profile = await axios.get("/profile", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      if (profile?.data?.verified) {
        localStorage.setItem("token", token);
        dispatch({
          type: SET_PROFILE,
          payload: {
            profile: profile?.data,
          },
        });
      }
      return profile?.data;
    } catch (error) {
      return error;
    }
  };
};

export const updateProfile = (values) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) return;
      const profile = await axios.post(
        "/profile",
        {
          name: values?.name,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          profile: values,
        },
      });
    } catch (error) {
      return error;
    }
  };
};

export const signin = (payload) => {
  return async (dispatch) => {
    try {
      const { email, password } = payload;
      const resp = await axios.post("/auth/signin", {
        email,
        password,
      });
      const { token, profile, verified } = resp?.data;
      if (verified) {
        localStorage.setItem("token", token);
        dispatch({
          type: SET_PROFILE,
          payload: { profile },
        });
      }
      return {
        success: true,
        verified,
      };
    } catch (error) {
      const status = error?.response?.status;
      if (status === 400 || status === 404) {
        return {
          success: false,
          error: error?.response?.data?.message,
        };
      }
      return {
        success: false,
        error: "Something went wrong",
      };
    }
  };
};
